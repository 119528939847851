import axios from 'axios'
import camelize from 'camelize'
import {
  axiosInstanceConfig,
  interceptRequest,
  interceptResponseSucess,
  interceptResponseError
} from './config'

const transformResponse = (data) => {
  try {
    if (data) {
      data = JSON.parse(data)
      data = camelize(data)
    }
  } catch (e) {
    // Keep unparsable data as is
  }
  return data
}

const axiosInstance = axios.create({
  ...axiosInstanceConfig,
  transformResponse
})

axiosInstance.interceptors.request.use(interceptRequest)

axiosInstance.interceptors.response.use(
  interceptResponseSucess,
  interceptResponseError
)

export default axiosInstance

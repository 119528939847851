import React from 'react'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Box, Flex, Image, P } from 'ui/acro'
import DrawerSectionHeader from './common/DrawerSectionHeader'
import DrawerResultTitleText from './common/DrawerResultTitleText'
import DrawerResultSubtitleText from './common/DrawerResultSubtitleText'
import styledComponents from 'styled-components'

const FlexBox = styledComponents(Flex)`
${css({
  flexDirection: 'column',
  gap: '1em'
})}
`

const PlaylistTitleBadge = styled(P)`
  ${css({
    position: 'absolute',
    fontFamily: 'Arquitecta, Arial, sans-serif',
    fontSize: '10px',
    color: 'white',
    fontWeight: '600',
    top: '5px',
    left: '0',
    right: '0',
    pr: '10px',
    pl: '10px',
    width: '100%',
    textAlign: 'center'
  })}
`

const PlaylistResultItems = ({ data, value }) => {
  const playlistData = data && data.playlists.top
  const count = data && data.playlists.resultCount

  if (playlistData && playlistData.length) {
    return (
      <FlexBox>
        <DrawerSectionHeader
          title={'Playlists'}
          count={count}
          href={`/search?q=${value}&tab=playlists`}
          padding={'0 0 .4375em 0'}
          display={['none', 'none', 'none', 'flex']}
        />
        {playlistData.slice(0, 3).map((item, idx) => (
          <Flex
            key={idx}
            id={'DrawerResultsPlaylist' + (idx + 1)} // +1 so we don't start with position 0
            as='a'
            className='unstyled'
            href={`/playlists/${item.uuid}`}
            alignItems='center'
            cursor='pointer'
            width='100%'
            onMouseDown={
              // Prevent onMouseDown event because onBlur is called on-mouse-down whereas click handler is on-mouse-up
              (e) => e.preventDefault()
            }
          >
            <Box position='relative' mr='1em'>
              <Image
                src={
                  item.thumbnailImage !== null
                    ? item.thumbnailImage
                    : 'https://alomoves.s3.amazonaws.com/manual_uploads/shared/search/placeholder-cards/square-card.jpg'
                }
                objectFit='cover'
                width='95px'
                height='95px'
              />
              <PlaylistTitleBadge>{item.title}</PlaylistTitleBadge>
            </Box>
            <Flex flexDirection='column'>
              {item.title && (
                <DrawerResultTitleText id='PlaylistTitle'>
                  {item.title}
                </DrawerResultTitleText>
              )}
              {item.author && (
                <DrawerResultSubtitleText>
                  By:{' '}
                  {item.author === 'Admin User'
                    ? `Alo Moves`
                    : `${item.author}`}
                </DrawerResultSubtitleText>
              )}
              <DrawerResultSubtitleText textTransform='none'>
                {item.classesCount && `${item.classesCount}`}{' '}
                {item.classesCount > 1 || item.classesCount === 0
                  ? `classes`
                  : `class`}
                {' • '}
                {item.followerCount && `${item.followerCount}`}{' '}
                {item.followerCount > 1 || item.followerCount === 0
                  ? `followers`
                  : `follower`}
              </DrawerResultSubtitleText>
            </Flex>
          </Flex>
        ))}
      </FlexBox>
    )
  } else {
    return <></>
  }
}

export default PlaylistResultItems

import {
  useQuery,
  useInfiniteQuery,
  useQueryClient,
  useMutation
} from 'react-query'
import axios from './axios.config'

const transformClassTimes = (cls) => {
  const now = new Date()
  const startTime = new Date(cls.startTime)
  const isDone = now.getTime() >= cls.startTime + cls.durationMs
  const isLive = now.getTime() >= cls.startTime && !isDone
  const isOpen = now.getTime() + cls.leadTimeMs >= cls.startTime && !isDone
  const durationMin = Math.floor(cls.durationMs / 60000)

  const day =
    now.toDateString() === startTime.toDateString()
      ? 'Today'
      : startTime.toLocaleDateString(undefined, {
          month: 'short',
          day: '2-digit'
        })

  const dayOfWeek =
    now.toDateString() === startTime.toDateString()
      ? 'Today'
      : startTime.toLocaleDateString(undefined, { weekday: 'long' })

  const time = startTime.toLocaleTimeString(undefined, {
    hour: 'numeric',
    minute: '2-digit'
  })

  const date = startTime.toLocaleDateString(undefined, {
    month: 'long',
    day: '2-digit',
    year: 'numeric'
  })

  const remainingTimeMins =
    now.getTime() >= startTime
      ? Math.floor(
          (startTime.getTime() + cls.durationMs - now.getTime()) / 60000
        )
      : durationMin

  return {
    ...cls,
    isOpen,
    isLive,
    isDone,
    day,
    dayOfWeek,
    time,
    date,
    remainingTimeMins,
    durationMin
  }
}

const getLiveClassesStatic = async (afterId = null) => {
  const res = await axios.get('/live/schedule/static', {
    params: {
      v: 1,
      after_id: Boolean(afterId) ? afterId : null
    }
  })
  /* We need to coerce the data to make sure that date and time info displays correctly wherever these classes are used. */
  const data = res.data.data.reduce((acc, obj) => {
    const cls = transformClassTimes(obj)
    acc.push(cls)
    return acc
  }, [])

  return {
    beforeId: afterId - 1,
    afterId: afterId + 1,
    ...res.data,
    data
  }
}

const getLiveClassDynamic = async (id) => {
  const res = await axios.get(`/live/class/${id}/dynamic`)
  if (res?.data) {
    return res.data
  }
}

export const prefetchLiveClassDynamic = (queryClient, id) => {
  queryClient.prefetchQuery(['liveClassDynamic', id], () =>
    getLiveClassDynamic(id)
  )
}

const getLiveClassStatic = async (id) => {
  const res = await axios.get(`/live/class/${id}/static`)
  if (res.data) {
    return transformClassTimes(res.data)
  } else {
    throw Error('Class not found')
  }
}

export const updateLiveClassScheduled = async (id, scheduled) => {
  const result = await axios.put(`live/my_schedule`, {
    changes: [{ class_id: id, in_my_schedule: scheduled }]
  })
  return result
}

export const useGetNextLiveClass = () => {
  const { data } = useGetLiveClassesStaticInfinite()
  const nextClass = data ? data.pages[0].data[0] : null
  return nextClass ? transformClassTimes(nextClass) : null
}

export const useGetLiveClassesStatic = (afterId) => {
  return useQuery('liveClassesStatic', () => getLiveClassesStatic(afterId))
}

export const useGetLiveClassesStaticInfinite = () => {
  return useInfiniteQuery(
    ['liveClassesStaticInfinite'],
    async ({ pageParam = 0 }) => {
      const classes = await getLiveClassesStatic(pageParam)

      return classes
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.afterId ? lastPage.afterId : false,
      getPreviousPageParam: (lastPage) =>
        lastPage.beforeId ? lastPage.beforeId : false
    }
  )
}

export const useGetLiveClassDynamic = (id) => {
  return useQuery(['liveClassDynamic', id], () => getLiveClassDynamic(id), {
    retry: false
  })
}

export const useGetLiveClassStatic = (id) => {
  return useQuery(['liveClassStatic', id], () => getLiveClassStatic(id), {
    retry: false
  })
}

export const useUpdateLiveClassDynamic = (id) => {
  const queryClient = useQueryClient()
  const { data } = useGetLiveClassDynamic(id)
  return useMutation(
    () => updateLiveClassScheduled(id, !(data?.inMySchedule || false)),
    {
      onMutate: async () => {
        // Cancel any ongoing queries
        await queryClient.cancelQueries(['liveClassDynamic', id])
        // Get the previous value (in case there's an error and we need to revert back to previous state)
        const previousDynamicClass = queryClient.getQueryData([
          'liveClassDynamic',
          id
        ])
        // Optimistically update the checkbox
        queryClient.setQueryData(['liveClassDynamic', id], (old) => ({
          ...old,
          scheduled: !old.scheduled
        }))
        return previousDynamicClass
      },
      // Error occurred - revert back to previous state
      onError: (_, _1, previousDynamicClass) =>
        queryClient.setQueryData(
          ['liveClassDynamic', id],
          previousDynamicClass
        ),
      // Success! invalidate the query to re-query the data in the background
      onSuccess: () => queryClient.invalidateQueries(['liveClassDynamic', id])
    }
  )
}

import React from 'react'
import { DesktopSearch } from 'images/latest'
import { ResponsiveSVG } from 'ui/acro'

const DesktopSearchIcon = ({ color, id }) => (
  <ResponsiveSVG
    width={['22px', '22px', '22px']}
    height={['22px', '22px', '22px']}
    className='unstyled'
    color={color}
    SVG={DesktopSearch}
    id={id}
  />
)

export default DesktopSearchIcon

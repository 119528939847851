import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Flex } from 'ui/acro'
import { ChevronRightIcon } from 'components_v2/Icons'
import Title from './Title'
import Count from './Count'

const FlexHeader = styled(Flex)`
  ${css({
    justifyContent: [
      'space-between',
      'space-between',
      'space-between',
      'flex-start'
    ],
    alignItems: 'center',
    width: '100%',
    gap: [null, null, null, '15px']
  })}
`
const GapContainer = styled(Flex)`
  ${css({
    alignItems: 'center',
    gap: '10px'
  })}
`

const DrawerSectionHeader = ({
  title,
  count,
  href,
  padding,
  display = 'flex'
}) => {
  return (
    <FlexHeader
      as='a'
      id={`Drawer${title}ResultsSeeAll`}
      className='unstyled'
      href={href}
      padding={padding}
      display={display}
    >
      <GapContainer>
        <Title>{title}</Title>
      </GapContainer>
      <GapContainer>
        {count && (
          <>
            <Count>{count}</Count>
            <ChevronRightIcon color='#636363' />
          </>
        )}
      </GapContainer>
    </FlexHeader>
  )
}

DrawerSectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  href: PropTypes.string.isRequired,
  padding: PropTypes.string
}

export default DrawerSectionHeader

export const FINISH_UPLOAD_FAIL = 'FINISH_UPLOAD_FAIL'
export const FINISH_UPLOAD_SUCCESS = 'FINISH_UPLOAD_SUCCESS'
export const INIT_UPLOAD = 'INIT_UPLOAD'
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
export const UPDATE_ARRAYS = 'UPDATE_ARRAYS'
export const UPLOAD_RETRY = 'UPLOAD_RETRY'
export const UPDATE_CHUNK_DATA = 'UPDATE_CHUNK_DATA'

export const updateProgress = (id, loaded, total) => ({
  type: UPDATE_PROGRESS,
  payload: {
    id,
    loaded,
    total
  }
})

export const initUpload = (id) => ({
  type: INIT_UPLOAD,
  payload: {
    id
  }
})

export const updateArrays = (id, part) => ({
  type: UPDATE_ARRAYS,
  payload: {
    id,
    part
  }
})

export const finishUploadFail = (id, error) => ({
  type: FINISH_UPLOAD_FAIL,
  payload: {
    id,
    error
  }
})

export const finishUploadSuccess = (id) => ({
  type: FINISH_UPLOAD_SUCCESS,
  payload: {
    id
  }
})

export const uploadRetry = (id, error) => ({
  type: UPLOAD_RETRY,
  payload: {
    id,
    error
  }
})

export const updateChunkData = (
  id,
  { uploadedParts, remainingParts, resumed, uuid }
) => ({
  type: UPDATE_CHUNK_DATA,
  payload: {
    id,
    uploadedParts,
    remainingParts,
    resumed,
    uuid
  }
})

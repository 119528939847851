import React from 'react'
import css from '@styled-system/css'
import styled from 'styled-components'

import DropdownMenu from 'components_v2/Header/Navbar/Menus/common/DropdownMenu'
import TopCategory from 'components_v2/Header/Navbar/Menus/common/TopCategory'

const TopCategoryDropdown = styled(DropdownMenu)(() =>
  css({
    position: 'absolute',
    width: '100%',
    top: '3.5em',
    left: '0',
    '.menu-container': {
      alignItems: 'unset'
    }
  })
)

const TopCategoryDropdownMenu = ({ hoverCategory }) => {
  return (
    <TopCategoryDropdown width='100%'>
      <TopCategory hoverCategory={hoverCategory} />
    </TopCategoryDropdown>
  )
}

export default TopCategoryDropdownMenu

import React from 'react'
import { Lotus } from 'images/latest'
import { ResponsiveSVG } from 'ui/acro'

const LotusIcon = ({ color = 'black', width = '97px', height = '61px' }) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    color={color}
    SVG={Lotus}
  />
)

export default LotusIcon

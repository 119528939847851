import { useEffect, useRef } from 'react'

/**
 * A mostly copy/paste of Dan Abramov's `useInterval` hook documented on his blog.
 *
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * */
const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up interval
  useEffect(() => {
    const tick = () => savedCallback.current()

    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default useInterval

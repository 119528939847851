import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Button } from 'ui/acro'

const SeeAllButton = styled(Button)`
  ${css({
    width: '100%',
    minWidth: '222px',
    maxWidth: [null, '300px', '300px', '300px'],
    border: '1px solid #A3A3A3',
    fontSize: '15px',
    fontWeight: 500,
    '@media only screen and (min-width: 1024px)': {
      '&:hover': {
        border: '1px solid #F2F2F2',
        backgroundColor: '#F2F2F2',
        color: 'black'
      }
    }
  })}
`
export default SeeAllButton

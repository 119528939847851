import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Image, NameList } from 'ui/acro'
import { ClassCountBadge } from 'components_v2/Home/SignedIn/common/components'
import DrawerResultTitleText from './DrawerResultTitleText'
import DrawerResultSubtitleText from './DrawerResultSubtitleText'

const SeriesResultItem = ({
  id,
  textColor,
  href,
  thumbnailImage,
  title,
  classesCount,
  coaches,
  category,
  subcategory
}) => {
  return (
    <Flex
      id={id}
      width='100%'
      as='a'
      className='unstyled'
      href={href}
      alignItems='center'
      cursor='pointer'
      onMouseDown={
        // Prevent onMouseDown event because onBlur is called on-mouse-down whereas click handler is on-mouse-up
        (e) => e.preventDefault()
      }
    >
      <Box position='relative' mr='1em'>
        {thumbnailImage ? (
          <Image
            src={thumbnailImage}
            objectFit='cover'
            width='95px'
            minWidth='95px'
            minHeight='95px'
          />
        ) : (
          <Box
            width='95px'
            minWidth='95px'
            minHeight='95px'
            backgroundColor='grey1'
          />
        )}
        {classesCount > 0 && (
          <ClassCountBadge
            fontSize='12px !important'
            bottom='8px !important'
            left='8px!important'
          >{`${classesCount} Class${
            classesCount <= 1 ? '' : 'es'
          }`}</ClassCountBadge>
        )}
      </Box>
      <Flex flexDirection='column'>
        {title && (
          <DrawerResultTitleText id='SeriesTitle' color={textColor}>
            {title}
          </DrawerResultTitleText>
        )}
        {coaches && (
          <DrawerResultSubtitleText id='SeriesSubtitle' color={textColor}>
            {coaches[0]['firstName'] !== undefined ? (
              <NameList people={coaches} />
            ) : (
              coaches.map((item, index) => {
                return (index ? ', ' : '') + item
              })
            )}
          </DrawerResultSubtitleText>
        )}
        <DrawerResultSubtitleText color={textColor}>
          {category && `${category}`}
          {subcategory && ` / ${subcategory}`}
        </DrawerResultSubtitleText>
      </Flex>
    </Flex>
  )
}

SeriesResultItem.propTypes = {
  id: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  thumbnailImage: PropTypes.string.isRequired,
  classesCount: PropTypes.number,
  coaches: PropTypes.array,
  category: PropTypes.string,
  subcategory: PropTypes.string
}

export default SeriesResultItem

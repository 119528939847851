import { useRef, useState } from 'react'

import 'swiper/swiper.min.css'

/**
 * This is a helper hook to give Swiper components access to navigation elements.
 * The hook will hide pagination dots if there is only 1 page and a paginationClass is supplied.
 *
 * @param {string} paginationClass the custom class name used to target pagination dots. Give to hook starting with a period (e.g. paginationClass = '.custom-swiper-pagination')
 *
 * @typedef {Object} swiperHookReturn
 * @property {function} getSwiperProps a function callback that returns the swiper config for typical use cases. Takes in a configuration object as an argument that will be merged with the rest of the configuration (see `GiftCardCarousel` for example usage).
 * @property {function} goNext go to next slide
 * @property {function} goPrev go to previous slide
 * @property {function} goToSlide go to a given slide, will autowrap if id < || > swiperInstance.length
 * @property {boolean} swiperInitialized whether or not the swiper instance has been properly initialized.
 * @property {number} activeIndex the currently active slide index
 * @property {boolean} isBeginning true if current active slide is first in array
 * @property {boolean} isEnd true if current active slide is last in array
 *
 * @returns {swiperHookReturn}
 */

const useSwiper = (paginationClass) => {
  const swiperRef = useRef(null)

  // We use these state variables to let the Prev/Next arrows know whether there is more to the carousel and, if so, to show themselves.
  // Pass these bool values to the Prev/Next components as a disabled={} prop
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(true)

  // track our active index.
  // Defaults to 0 so child elements can render properly, but will update if another initial index is set, as it is set by swiperDefaultConfig.on.init
  const [activeIndex, setActiveIndex] = useState(0)

  // Tracks if it's initialized or not
  const [swiperInitialized, setInitialized] = useState(false)

  // We only show pagination if Swiper has pages that aren't visible
  // In order to have responsive pagination, the <Swiper/> Component needs to have watchSlidesVisibility option set to true and the useSwiper() hook needs to be given a pagination class name to observe.
  const responsivePagination = () => {
    if (paginationClass && swiperRef.current.params.watchSlidesVisibility) {
      const pagination = document.getElementsByClassName(
        paginationClass.slice(1)
      )[0]
      if (pagination) {
        // If there is more than 1 visible page, then we do not need pagination hints. Likewise, if all of the slides are visible, then we don't need pagination either.
        // We might need to refactor this so that passed in arguments can trigger these states (we may wish to show pagination bullets on multi-slide layouts), but it works for now.
        if (
          swiperRef.current.slides.length <= 1 ||
          swiperRef.current.pagination.bullets.length === 1
        ) {
          pagination.style.display = 'none'
        } else {
          pagination.style.display = 'flex'
        }
      }
    }
  }

  const goToSlide = (id) => {
    // Manually update the activeIndex on the click handler as clicking on slides doesn't trigger on.slideChange event when swiping is disabled (e.g. VP3 > screens)
    if (swiperRef.current) {
      if (id < swiperRef.current.slides.length) {
        swiperRef.current.slideToLoop(id)
        setActiveIndex(id)
      } else {
        swiperRef.current.slideToLoop(0)
        setActiveIndex(0)
      }
    }
  }

  const goNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext(300)
    }
  }

  const goPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev(300)
    }
  }

  const getSwiperProps = (config) => {
    const { onSlideChange, ...restConfig } = config
    return {
      onSwiper: (swiper) => {
        if (swiper && swiperRef.current === null) {
          swiperRef.current = swiper
          // Initialize some things
          setIsBeginning(swiperRef.current.isBeginning)
          setIsEnd(swiperRef.current.isEnd)
          responsivePagination()
          setInitialized(true)
        }
      },
      onInit: (swiper) => {
        setActiveIndex(swiper.realIndex)
      },
      onSlideChange: (swiper) => {
        setActiveIndex(swiper.realIndex)
        if (onSlideChange && typeof onSlideChange === 'function')
          onSlideChange(swiper.realIndex)
      },
      onReachBeginning: () => {
        setIsBeginning(true)
      },
      onFromEdge: (swiper) => {
        setIsBeginning(swiper.isBeginning)
        setIsEnd(swiper.isEnd)
      },
      onReachEnd: (swiper) => {
        setIsEnd(swiper.isEnd)
      },
      onResize: () => {
        responsivePagination()
        swiperRef.current.update()
      },
      ...restConfig
    }
  }

  return {
    // Setter
    getSwiperProps,
    // Actions
    goNext,
    goPrev,
    goToSlide,
    // State
    swiperInitialized,
    activeIndex,
    isBeginning,
    isEnd
  }
}

export default useSwiper

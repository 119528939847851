import React from 'react'

/**
 * Allows us to easily render either a react element or function.
 * Passing in an invalid element (or function that returns an invalid element).
 * Providing an invalid Component will render Default, if provided.
 * @param {function || Node } Component 
 * @param {Component} Default 
 * 
 * @returns {Node} a valid node.
 */
const renderPropComponent = (Component, Default = <></>) => {
  if (React.isValidElement(Component)) return Component
  
  if (typeof Component === 'function') {
    const fnComponent = <Component />
    if (React.isValidElement(fnComponent)) {
      return fnComponent
    }
  }


  console.warn('You must supply a valid React component or function that returns a valid React component. Rendering Fragment instead.')
  if (React.isValidElement(Default))
    return Default
}

export default renderPropComponent

import { useEffect, useState } from 'react'
import { getCountry } from 'helpers/getCountry'

const useGetCountry = (fallback = true) => {
  const [country, setCountry] = useState('')
  useEffect(() => {
    if (!country) {
      getCountry(fallback).then((res) => {
        setCountry(res)
      })
    }
  }, [fallback])

  return country
}

export default useGetCountry

import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { FacebookSVG } from 'images/latest'

const FacebookIcon = ({ color, width = '24px', height = '24px' }) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    color={color}
    SVG={FacebookSVG}
  />
)

export default FacebookIcon

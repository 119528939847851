import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { HamburgerMenu } from 'images/latest'
const HamburgerMenuIcon = ({ width = '20px', height = '20px', color, id }) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled hamburger-icon'
    color={color}
    SVG={HamburgerMenu}
    id={id}
  />
)

export default HamburgerMenuIcon

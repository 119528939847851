import React from 'react'
import { AnimationA } from 'images/latest'
import { ResponsiveSVG } from 'ui/acro'

const AnimationAIcon = ({
  color = 'transparent',
  width = '43px',
  height = '44px'
}) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled animation-fill-1'
    color={color}
    SVG={AnimationA}
  />
)

export default AnimationAIcon

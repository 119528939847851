import React, { useRef, useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { css } from '@styled-system/css'
import styled from 'styled-components'
import { P, Flex, Box, Fade, componentHeights as heights } from 'ui/acro'
import ClassResultItems from './ClassResultItems'
import SeriesResultItems from './SeriesResultItems'
import PlaylistResultItems from './PlaylistResultItems'
import InstructorResultItems from './InstructorResultItems'
import DrawerMobileAccordions from './DrawerMobileAccordions'
import MemberResultItems from './MemberResultItems'
import SeeAllButton from './common/SeeAllButton'
import { useWindowResize } from 'hooks'

const DrawerResultsContainer = styled(Box)`
  ${css({
    pt: [
      `calc(16px + ${heights.navbar.mobile})`,
      `calc(16px + ${heights.navbar.mobile})`,
      `calc(16px + ${heights.navbar.mobile})`,
      '0'
    ],
    overflow: 'auto',
    height: '100%',
    pb: 'env(safe-area-inset-bottom)'
  })}
`
const FlexResultSections = styled(Flex)`
  ${css({
    display: ['flex', 'flex', 'flex', 'grid'],
    width: '100%',
    flexDirection: ['column', null, null, null],
    gridTemplateColumns: [null, null, null, 'repeat(4, 1fr)'],
    gap: ['1em', '1em', '1em', '3.08em'],
    padding: ['0 1em', '0 4.0625em', '0 4.0625em', '0 3.125em'],
    mb: ['1em', '2em', '2em', '2.5em']
  })}
`
const FlexHalfSections = styled(Flex)`
  ${css({
    gap: '1em',
    flexWrap: 'wrap',
    alignContent: 'space-between',
    width: '100%'
  })}
`
const MobileAccordionLinksContainer = styled(Flex)`
  ${css({
    display: ['flex', 'flex', 'flex', 'none'],
    flexDirection: 'column',
    position: 'fixed',
    width: '100%',
    bottom: '0',
    zIndex: '2',
    pb: 'env(safe-area-inset-bottom)',
    backgroundColor: 'white'
  })}
`
const NoResultsP = styled(P)`
  ${css({
    fontSize: '16px',
    padding: ['40px 0', '40px 0', '40px 0', '20px 0'],
    textAlign: 'center'
  })}
`
const FlexContainer = styled(Flex)`
  ${css({
    px: ['16px', '30px'],
    justifyContent: 'center'
  })}
`

const DrawerResults = ({ data, value, handleSubmit }) => {
  const [buttonMargin, setButtonMargin] = useState(null)
  const [paddingBottom, setPaddingBottom] = useState(null)
  const totalResultsNumber = data && data.totalResultsReturned

  // on mobile screens, watch the height of the viewport to adjust the padding for the bottom bar on safari/chrome
  const [innerHeight, setInnerHeight] = useState()
  const [mobileBrowserBottom, setMobileBrowserBottom] = useState()
  const { height } = useWindowResize()
  useEffect(() => {
    setInnerHeight(height)
    // this needs to be reworked - ideally with a package to detect the mobile browser bottom bars
    if (innerHeight > 700) {
      // if bottom bar isn't showing
      setMobileBrowserBottom('1em')
    } else {
      // if bottom bar is showing
      setMobileBrowserBottom('8em')
    }
  }, [height, innerHeight])

  const containerHeightRef = useRef(null)
  useEffect(() => {
    if (containerHeightRef) {
      setButtonMargin(containerHeightRef.current?.clientHeight)
      setPaddingBottom([
        `calc(${buttonMargin}px + ${mobileBrowserBottom})`,
        `calc(${buttonMargin}px + ${mobileBrowserBottom})`,
        `calc(${buttonMargin}px + ${mobileBrowserBottom})`,
        '1.25em'
      ])
    }
  }, [containerHeightRef, buttonMargin, value, data, mobileBrowserBottom])

  return (
    <Fragment>
      {value &&
        (totalResultsNumber > 0 ? (
          <DrawerResultsContainer id='SearchDrawer'>
            <Fade>
              <FlexResultSections>
                <ClassResultItems data={data} value={value} />
                <SeriesResultItems data={data} value={value} />
                <PlaylistResultItems data={data} value={value} />
                <FlexHalfSections>
                  <InstructorResultItems data={data} value={value} />
                  <MemberResultItems data={data} value={value} />
                </FlexHalfSections>
              </FlexResultSections>
            </Fade>
            {totalResultsNumber >= 5 && (
              <FlexContainer pb={paddingBottom}>
                <SeeAllButton
                  id={'DrawerSeeAllResultsButton'}
                  variant='tertiary'
                  onClick={handleSubmit}
                  onMouseDown={
                    // Prevent onMouseDown event from bubbling up. onBlur is called on-mouse-down whereas click handler is on-mouse-up
                    (e) => e.preventDefault()
                  }
                >
                  See All {data.totalResultsReturned} Results
                </SeeAllButton>
              </FlexContainer>
            )}
            <MobileAccordionLinksContainer ref={containerHeightRef}>
              <DrawerMobileAccordions data={data} value={value} />
            </MobileAccordionLinksContainer>
          </DrawerResultsContainer>
        ) : (
          // keep drawer open to show 'no results for ____' if no data is returned for query
          <Fade in={totalResultsNumber <= 0}>
            <FlexContainer mt={['60px', '60px', '60px', '0']}>
              <NoResultsP>No results for "{value}"</NoResultsP>
            </FlexContainer>
          </Fade>
        ))}
    </Fragment>
  )
}

DrawerResults.propTypes = {
  data: PropTypes.any,
  value: PropTypes.string,
  handleSubmit: PropTypes.func
}

export default DrawerResults

import { useQuery } from 'react-query'
import axios from './axios.config'

const getSupportCategoryList = async () => {
  const res = await axios.get(`/support/`)
  if (res.data) {
    return {
      data: res.data
    }
  } else {
    throw Error('Data not found')
  }
}

const getSupportCategoryDocumentList = async (category) => {
  const res = await axios.get(`/support/${category}`)
  if (res.data) {
    return {
      data: res.data
    }
  } else {
    throw Error('Data not found')
  }
}

const getSupportDocument = async (category, slug) => {
  const res = await axios.get(`/support/${category}/${slug}`)
  if (res.data) {
    return {
      data: res.data
    }
  } else {
    throw Error('Data not found')
  }
}

const searchSupportDocuments = async (input) => {
  const res = await axios.get(`/support/search?q=${input}`)
  if (res.data) {
    return {
      data: res.data
    }
  } else {
    throw Error('Data not found')
  }
}

export const useGetSupportCategoryList = () => {
  return useQuery('useGetSupportCategoryList', () => getSupportCategoryList())
}

export const useGetSupportCategoryDocumentList = (category) => {
  return useQuery(['useGetSupportCategoryDocumentList', category], () =>
    getSupportCategoryDocumentList(category)
  )
}

export const useGetSupportDocument = (category, slug) => {
  return useQuery(['useGetSupportDocument', category, slug], () =>
    getSupportDocument(category, slug)
  )
}

export const useSearchSupportDocuments = (query) => {
  const sanitizedQuery = query.replace(/[^A-Za-z0-9 ]/g, '');
  return useQuery(
    ['useSearchSupportDocuments', sanitizedQuery],
    () => searchSupportDocuments(sanitizedQuery),
    {
      // only fetch search terms longer than 2 characters
      enabled: sanitizedQuery.length > 2
    }
  )
}

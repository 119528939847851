/**
 * Determines whether input is within range of two given numbers, exclusive of the last number.
 * @param {number} x - The number we want to find within the given range.
 * @param {number} min - The lower bound of the range, inclusive.
 * @param {number} max - The upper bound of the range, exclusive.
 * 
 * @returns {boolean} - whether the number is within the range.
 */

const isInRange = (x, min, max) => {
  return ((x - min) * (x - max) < 0)
}

export default isInRange
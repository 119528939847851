import styled from 'styled-components'
import { css } from '@styled-system/css'
import { P } from 'ui/acro'

const TimeCodeBadge = styled(P)(() =>
  css({
    bg: 'white',
    color: 'black',
    fontFamily: 'Arquitecta',
    wordBreak: 'none',
    fontSize: '13px',
    position: 'absolute',
    padding: '0px 5px',
    lineHeight: '170%',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    margin: '0' // overwrite legacy p margin in application.css
  })
)

export default TimeCodeBadge

import styled from 'styled-components'
import { css } from '@styled-system/css'
import { P } from 'ui/acro'

const DrawerResultSubtitleText = styled(P)(() =>
  css({
    fontFamily: 'Proxima-Nova',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: 'normal',
    lineHeight: '16px',
    color: '#636363',
    margin: '2px 0'
  })
)

export default DrawerResultSubtitleText

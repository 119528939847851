import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { CommunityBarre } from 'images/latest'

const CommunityBarreIcon = ({ color = 'black' }) => (
  <ResponsiveSVG
    width='43px'
    height='33px'
    className='unstyled'
    fill={color}
    SVG={CommunityBarre}
  />
)

export default CommunityBarreIcon

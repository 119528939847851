import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { HeartLike } from 'images/latest'

const HeartLikeIcon = ({
  color = '#BFBFBF',
  width = '35px',
  height = '31px'
}) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    fill={color}
    SVG={HeartLike}
  />
)

export default HeartLikeIcon

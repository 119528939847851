import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useModal_deprecated, useGetCountry } from 'hooks'

import {
  Box,
  Fade,
  Flex,
  HR,
  Metadata,
  PrivacyModal,
  TermsModal
} from 'ui/acro'

import footerHeight from './_footerHeight'
import FooterLinks from './FooterLinks'

const Footer = ({ links, flush }) => {
  const year = new Date().getFullYear()
  const terms = useModal_deprecated()
  const privacy = useModal_deprecated()
  const country = useGetCountry()

  return (
    <Fade>
      <Fragment>
        <Box width='100%'>
          <HR />
          <Flex
            height={footerHeight({ links: links, flush: flush })}
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
          >
            <HR />
            <Box>
              {links && <FooterLinks />}
              <Flex justifyContent='center'>
                <Metadata margin='0px'>
                  &copy; {year} Alo Moves. All Rights Reserved.
                </Metadata>
              </Flex>
              <Flex justifyContent='center'>
                <Metadata
                  cursor='pointer'
                  onClick={terms.open}
                  mb='0px'
                  mr='16px'
                  id='terms'
                >
                  Terms
                </Metadata>
                <Metadata
                  cursor='pointer'
                  onClick={privacy.open}
                  mb='0px'
                  id='privacy'
                >
                  Privacy
                </Metadata>
                <Metadata
                  cursor='pointer'
                  onClick={() => window.Osano?.cm?.showDrawer()}
                  ml='16px'
                  mb='0px'
                  id='cookies'
                >
                  Cookies
                </Metadata>
              </Flex>
            </Box>
          </Flex>
        </Box>

        {terms.active && <TermsModal useModal={terms} country={country} />}
        {privacy.active && <PrivacyModal useModal={privacy} />}
      </Fragment>
    </Fade>
  )
}

Footer.defaultProps = {
  links: true,
  flush: {
    mobile: false,
    desktop: false
  }
}

Footer.propTypes = {
  links: PropTypes.bool,
  flush: PropTypes.object
}

export default Footer

export const yogaCategories = {
  category: 'Yoga',
  classFinderLink: '/class-finder?styles=yoga&order=date&',
  subcategories: [
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Ashtanga.jpg',
      title: 'Ashtanga',
      url: '/class-finder?styles=yoga_ashtanga&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Hatha.jpg',
      title: 'Hatha',
      url: '/class-finder?styles=yoga_hatha&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Kids-Yoga.jpg',
      title: 'Kids Yoga',
      url: '/class-finder?styles=yoga_kids-yoga&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Kundalini.jpg',
      title: 'Kundalini',
      url: '/class-finder?styles=yoga_kundalini&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Prenatal.jpg',
      title: 'Prenatal/Postnatal',
      url: '/class-finder?styles=yoga_prenatal-postnatal&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Restorative.jpg',
      title: 'Restorative',
      url: '/class-finder?styles=yoga_restorative&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Vinyasa.jpg',
      title: 'Vinyasa',
      url: '/class-finder?styles=yoga_vinyasa&amp;order=date&amp;'
    }
  ]
}

export const fitnessCategories = {
  category: 'Fitness',
  classFinderLink: '/class-finder?styles=fitness&order=date&',
  subcategories: [
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Barre.jpg',
      title: 'Barre',
      url: '/class-finder?styles=fitness_barre&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Core.jpg',
      title: 'Core',
      url: '/class-finder?styles=fitness_core&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Dance.jpg',
      title: 'Dance',
      url: '/class-finder?styles=fitness_dance&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Gym.jpg',
      title: 'Gym',
      url: '/class-finder?styles=fitness_gym&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_HIIT.jpg',
      title: 'HIIT',
      url: '/class-finder?styles=fitness_hiit&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Pilates.jpg',
      title: 'Pilates',
      url: '/class-finder?styles=fitness_pilates&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Prenatal.jpg',
      title: 'Prenatal/Postnatal',
      url: '/class-finder?styles=fitness_prenatal-postnatal&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Running.jpg',
      title: 'Running',
      url: '/explore/filter?categories=fitness_running&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Sculpt.jpg',
      title: 'Sculpt',
      url: '/class-finder?styles=fitness_sculpt&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Strength.jpg',
      title: 'Strength',
      url: '/class-finder?styles=fitness_strength&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Stretching.jpg',
      title: 'Stretching',
      url: '/class-finder?styles=fitness_stretching&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Walking.jpg',
      title: 'Walking',
      url: '/class-finder?styles=fitness_walking&amp;order=date&amp;'
    }
  ]
}

export const mindfulnessCategories = {
  category: 'Mindfulness',
  classFinderLink: '/class-finder?styles=mindfulness&order=date&',
  subcategories: [
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Breathwork.jpg',
      title: 'Breathwork',
      url: '/class-finder?styles=mindfulness_breathwork&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Meditation.jpg',
      title: 'Meditation',
      url: '/class-finder?styles=mindfulness_meditation&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Reiki.jpg',
      title: 'Reiki',
      url: '/class-finder?styles=mindfulness_reiki&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Sound.jpg',
      title: 'Sound',
      url: '/class-finder?styles=mindfulness_sound&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Yoga+Nidra.jpg',
      title: 'Yoga Nidra',
      url: '/class-finder?styles=mindfulness_yoga-nidra&amp;order=date&amp;'
    }
  ]
}

export const wellnessCategories = {
  category: 'Wellness',
  classFinderLink: '/class-finder?styles=wellness&order=date&',
  subcategories: [
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/CycleSyncing.jpg',
      title: 'Cycle Syncing',
      url: '/class-finder?styles=wellness_cycle-syncing&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/Nutrition.jpeg',
      title: 'Nutrition',
      url: '/class-finder?styles=wellness_nutrition&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/Personal-Growth.jpg',
      title: 'Personal Growth',
      url: '/class-finder?styles=wellness_personal-growth&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/SelfCare.jpg',
      title: 'Self-Care',
      url: '/class-finder?styles=wellness_self-care&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/Sleep.jpg',
      title: 'Sleep',
      url: '/class-finder?styles=wellness_sleep&amp;order=date&amp;'
    }
  ]
}

export const skillsCategories = {
  category: 'Skills',
  classFinderLink: '/class-finder?styles=skills&order=date&',
  subcategories: [
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Arm-Balances.jpg',
      title: 'Arm Balances',
      url: '/class-finder?styles=skills_arm-balances&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Backbends.jpg',
      title: 'Backbends',
      url: '/class-finder?styles=skills_backbends&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Flexibility.jpg',
      title: 'Flexibility',
      url: '/class-finder?styles=skills_flexibility&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__How-To-Teach.jpg',
      title: 'How to Teach',
      url: '/class-finder?styles=skills_how-to-teach&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Inversion.jpg',
      title: 'Inversions',
      url: '/class-finder?styles=skills_inversions&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Mobility.jpg',
      title: 'Mobility',
      url: '/class-finder?styles=skills_mobility&amp;order=date&amp;'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Yoga-Poses.jpg',
      title: 'Yoga Poses',
      url: '/class-finder?styles=skills_yoga-poses&amp;order=date&amp;'
    }
  ]
}

export const difficultyClasses = {
  title: 'Difficulty',
  links: [
    {
      label: 'Introductory',
      url: '/class-finder?difficulties=introductory&order=date'
    },
    {
      label: 'Beginner',
      url: '/class-finder?difficulties=beginner&order=date'
    },
    {
      label: 'Moderate',
      url: '/class-finder?difficulties=moderate&order=date'
    },
    {
      label: 'Intermediate',
      url: '/class-finder?difficulties=intermediate&order=date'
    },
    {
      label: 'Advanced',
      url: '/class-finder?difficulties=advanced&order=date'
    }
  ]
}

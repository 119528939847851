import React, { createContext, useContext, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { loadStripe } from '@stripe/stripe-js'

export const StripeCheckoutContext = createContext(null)
export const useStripeCheckout = () => useContext(StripeCheckoutContext)

/**
 * The StripeCheckoutProvider is responsible for providing the stripe promise object, that will be used
 * for redirecting to stripe checkout (otherwise known as stripe SCA).
 * https://stripe.com/docs/strong-customer-authentication
 *
 * Note: This is the new StripeCheckoutProvider, as the old StripeCheckoutProvider is used to provide the stripe object
 * for stripe elements (we're currently moving towards everything stripe checkout so this implementation
 * should be used in favor of the old one).
 *
 * Usage:
 *   const getStripe = useStripeCheckout()
 *   later on in the code, preferably in an event handler...
 *   const stripe = await getStripe()
 */
const StripeCheckoutProvider = ({ children, stripeApiKey }) => {
  // Using a ref here and wrapping it in a closure
  // since we don't want to re-initialize the stripe call if the
  // provider re-renders for some reason.
  const stripeRef = useRef(null)

  const getStripe = useCallback(() => {
    if (stripeRef.current === null) {
      stripeRef.current = loadStripe(stripeApiKey)
    }
    return stripeRef.current
  }, [stripeApiKey])

  return (
    <StripeCheckoutContext.Provider value={getStripe}>
      {children}
    </StripeCheckoutContext.Provider>
  )
}

StripeCheckoutProvider.propTypes = {
  children: PropTypes.node,
  stripeApiKey: PropTypes.string.isRequired
}

export default StripeCheckoutProvider

import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Flex, ResponsiveSVG } from 'ui/acro'
import { DrawerSectionHeader } from './common'
import { Classes, Instructors, Members, Playlists, Series } from 'images/latest'
import { useWindowResize } from 'hooks'

const MobileAccordionLink = styled(Flex)`
  ${css({
    alignItems: 'center',
    width: '100%',
    gap: '10px',
    borderTop: '1px solid #E1E1E1',
    backgroundColor: 'white',
    justifyContent: 'space-between',
    padding: '8px 15px'
  })}
`

const DrawerMobileAccordions = ({ data, value }) => {
  const accordionTitles = [
    {
      title: 'instructors',
      count: data.instructors.resultCount,
      icon: Instructors
    },
    {
      title: 'classes',
      count: data.classes.resultCount,
      icon: Classes
    },
    {
      title: 'series',
      count: data.series.resultCount,
      icon: Series
    },
    {
      title: 'playlists',
      count: data.playlists.resultCount,
      icon: Playlists
    },
    {
      title: 'members',
      count: data.users.resultCount,
      icon: Members
    }
  ]

  const [size, setSize] = useState()
  const { width } = useWindowResize()
  useEffect(() => {
    setSize(width < 1200 ? 'mobile' : 'desktop')
  }, [width])
  const isMobile = size === 'mobile'

  if (data && isMobile) {
    return (
      <Fragment>
        {accordionTitles.map((accordion, idx) => {
          const hidden = accordion.count <= 0
          return (
            <MobileAccordionLink display={hidden ? 'none' : 'flex'}>
              <ResponsiveSVG
                SVG={accordion.icon}
                fill='black'
                width='24px'
                height='20px'
              />
              <DrawerSectionHeader
                key={idx}
                title={accordion.title}
                count={accordion.count}
                href={`/search?q=${value}&tab=${accordion.title}`}
              />
            </MobileAccordionLink>
          )
        })}
      </Fragment>
    )
  } else {
    return <></>
  }
}

export default DrawerMobileAccordions

import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: null
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      errorMessage: error.message
    };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error)
    console.log(errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <h1 style={{ marginBottom: '16px', paddingTop: '16px'}}>Something went wrong.</h1>
          {this.state.errorMessage && (<p style={{ color: 'red' }}>{this.state.errorMessage}</p>)}
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary
import { useState, useRef } from 'react'

const useModal = (closeCallback) => {
  const ref = useRef()
  const [active, setActive] = useState(false)
  const [overlay, setOverlay] = useState(false)

  const open = () => {
    // Overlay will not transition until active = true (see ui/acro/blocks/Modal.js)
    setOverlay(true)
    setActive(true)
  }

  const close = () => {
    setOverlay(false)

    // Wait for overlay transition to finish
    setTimeout(() => {
      setActive(false)
      if (closeCallback) closeCallback()
    }, 150)
  }

  return {
    ref,
    // State
    active,
    overlay,
    // Actions
    open,
    close
  }
}

export default useModal

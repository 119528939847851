import styled from 'styled-components'
import { css } from '@styled-system/css'
import { P } from 'ui/acro'

const DrawerResultTitleText = styled(P)(() =>
  css({
    fontFamily: 'Proxima-Nova',
    fontSize: '16px',
    fontWeight: ['400', '600', '600', '400'],
    letterSpacing: 'normal',
    lineHeight: '19.2px',
    color: 'black',
    margin: '2px 0'
  })
)

export default DrawerResultTitleText

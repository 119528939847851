import styled from 'styled-components'
import { css } from '@styled-system/css'
import Text from 'ui/acro/typography/Text'

const HeaderCTAText = styled(Text)(() =>
  css({
    display: 'flex',
    alignItems: 'start',
    fontFamily: 'Proxima-Nova',
    fontSize: ['14px', '16px'],
    fontWeight: '700',
    letterSpacing: 'normal',
    lineHeight: '105%',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    '> span': {
      borderBottom: '1px solid #000'
    }
  })
)
export default HeaderCTAText

import React from 'react'
import styled from 'styled-components'
import css from '@styled-system/css'

import DropdownMenu from 'components_v2/Header/Navbar/Menus/common/DropdownMenu'
import MyPractice from 'components_v2/Header/Navbar/Menus/common/MyPractice'

const MyPracticeDropdown = styled(DropdownMenu)(() =>
  css({
    position: 'absolute',
    width: '100%',
    top: '3.5em',
    left: '0',
    '.menu-container': {
      alignItems: 'unset'
    }
  })
)

const MyPracticeDropdownMenu = () => {
  return (
    <MyPracticeDropdown width='100%'>
      <MyPractice />
    </MyPracticeDropdown>
  )
}

export default MyPracticeDropdownMenu

import { useEffect } from 'react'

// This hook checks whether a mouseclick occurs within an element that has been opened (modal or dropdown).
// It also detects whether a user presses escape.
// It needs a ref to the element (useRef()), a handler callback function (e.g. to close dropdown), and the state of whether the component is open or not.
// To have useOutsideClick() properly seated in the DOM, you must wrap any element you want to target with <div ref={ref}>.

const useOutsideClick = (ref, handler, open) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        // if we click inside of the current element or its children, do nothing
        return
      }
      handler()
    }

    const escapeListener = (event) => {
      const key = event.key || event.keyCode
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        handler()
      }
    }

    if (open) {
      document.addEventListener('keyup', escapeListener)
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
    } else {
      document.removeEventListener('keyup', escapeListener)
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }

    return () => {
      document.removeEventListener('keyup', escapeListener)
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [open, handler])
}

export default useOutsideClick

import styled from 'styled-components'
import { css } from '@styled-system/css'

export const HalfDivider = styled('hr')`
  ${css({
    margin: '15px 0px',
    opacity: '0.6',
    width: '100%',
    borderColor: `${(props) => props.theme.colors.mercury}`
  })}
`

export const FullDivider = styled(HalfDivider)`
  ${css({
    width: '100%'
  })}
`

import React, { createContext, useContext, useReducer } from 'react'

// Manually import here to prevent circular dependency w/ useModal() hook.
import useBodyScrollLock from 'hooks/useBodyScrollLock'
import useKeyboardTrap_Dangerous from 'hooks/useKeyboardTrap_Dangerous'

import reducer, { INITIAL_STATE } from './reducer'
import {
  setModalStart,
  setModalFinish,
  closeModalStart,
  closeModalFinish,
  changeTitle
} from './actions'

import ModalComponent, { ModalBody } from './ModalComponent'

const ModalStore = createContext(null)
export const useModalContext = () => useContext(ModalStore)

/**
 * This ModalProvider creates a context for a Modal and exposes all children to an openModal() and closeModal() function.
 * When called, these functions will mount a new Modal to the DOM or dispose of it (open and close accordingly)
 * useModal() can ONLY be used within the context of <ModalProvider />
 *
 * @param {Node} children
 */

const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const toggleBodyLock = useBodyScrollLock()
  const {
    setKeyboardTrap_Dangerous,
    clearKeyboardTrap_Dangerous
  } = useKeyboardTrap_Dangerous('modal-container') // id for modal-container defined in ModalComponent.js

  /**
   * A function to close a modal and clean it up from the DOM.
   */
  const closeModal = () => {
    if (state.closeable) {
      dispatch(closeModalStart())
      // Wait for overlay transition to finish before unmounting Modal and cleaning up.
      setTimeout(() => {
        // If user had a previous focus, reset focus to the element on modal close.
        if (state.prevFocus) {
          state.prevFocus.focus()
        }

        // Run onClose callbacks supplied to modal via options
        if (state.onClose) {
          state.onClose()
        }
        toggleBodyLock()
        clearKeyboardTrap_Dangerous()
        dispatch(closeModalFinish())
      }, state.transitionSpeed)
    }
  }

  /**
   * A function that creates a new Modal and mounts it to the DOM.
   *
   * @param {Component} Modal - A valid React Component or function which returns a valid React Component
   * @param {Object} options - Options for the modal.
   * @param {boolean} [options.fullWidth=true] - Should the modal take up full width
   * @param {function} [options.onClose] - onClose callback function
   * @param {boolean} [options.responsiveVideo] - Styles the modal to properly hold a responsive videojs video.
   * @param {boolean} [options.showClose=true] - Should the close button be shown or hidden
   * @param {number} [options.transitionSpeed=300] - How fast should transitions take (e.g. hide/show modal and overlay)
   * @param {string} [options.title] - An optional title for the Modal
   */

  const openModal = (Modal, options = {}) => {
    // set our defaults here.
    let {
      fullWidth = true,
      onClose = null,
      responsiveVideo = false,
      showClose = true,
      transitionSpeed = 300,
      title = null
    } = options

    // Type check the options and set to defaults if invalid types supplied.
    // We can probably refactor this out, but I'm not entirely clear on the best path forward there.
    if (onClose && typeof onClose !== 'function') {
      console.error('onClose must be a function')
      onClose = null
    }
    if (transitionSpeed && typeof transitionSpeed !== 'number') {
      console.error('transitionSpeed must be a number')
      transitionSpeed = 300
    }
    if (showClose && typeof showClose !== 'boolean') {
      console.error('showClose must be a boolean')
      showClose = true
    }
    if (fullWidth && typeof fullWidth !== 'boolean') {
      console.error('fullWidth must be a boolean')
      fullWidth = true
    }

    if (responsiveVideo && typeof responsiveVideo !== 'boolean') {
      console.error('responsiveVideo must be a boolean')
      fullWidth = true
    }

    // Get user's previous focus so we can return it after they dismiss the modal.
    const prevFocus = document.activeElement

    dispatch(
      setModalStart(Modal, {
        onClose,
        fullWidth,
        prevFocus,
        responsiveVideo,
        showClose,
        transitionSpeed,
        title
      })
    )

    // Wait for the modal to fully transition in before allowing a close, as well as locking Body scrolling, and setting keyboard trap within modal.
    // This is to prevent accidental closures from fast clicking.
    setTimeout(() => {
      dispatch(setModalFinish())
      toggleBodyLock()
      setKeyboardTrap_Dangerous()
    }, transitionSpeed)
  }

  const setTitle = (title) => {
    dispatch(changeTitle(title))
  }

  return (
    <ModalStore.Provider value={{ openModal, closeModal, setTitle, ModalBody }}>
      {/* We destructure our state into ModalComponent so we don't get a circular dependency between ModalProvider and ModalComponent */}
      {state.ModalChild && (
        <ModalComponent closeModal={closeModal} {...state} />
      )}
      {children}
    </ModalStore.Provider>
  )
}

export default ModalProvider

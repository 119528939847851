import React from 'react'
import { Flex } from 'ui/acro'
import DrawerSectionHeader from './common/DrawerSectionHeader'
import styledComponents from 'styled-components'
import { css } from '@styled-system/css'
import { SeriesResultItem } from './common'

const FlexBox = styledComponents(Flex)`
${css({
  flexDirection: 'column',
  gap: '1em'
})}
`

const SeriesResultItems = ({ data, value }) => {
  const seriesData = data && data.series.top
  const count = data && data.series.resultCount

  if (seriesData && seriesData.length) {
    return (
      <FlexBox>
        <DrawerSectionHeader
          title={'Series'}
          count={count}
          href={`/search?q=${value}&tab=series`}
          padding={'0 0 .4375em 0'}
          display={['none', 'none', 'none', 'flex']}
        />
        {seriesData.slice(0, 3).map((item, idx) => {
          return (
            <SeriesResultItem
              key={idx}
              id={'DrawerResultsSeries' + (idx + 1)} // +1 so we don't start with position 0
              href={item.url}
              thumbnailImage={item.thumbnailImage}
              classesCount={item.classesCount}
              title={item.title}
              coaches={item.coaches}
              category={item.category}
              subcategory={item.subcategory}
            />
          )
        })}
      </FlexBox>
    )
  } else {
    return <></>
  }
}

export default SeriesResultItems

import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Image } from 'ui/acro'

const CircleImage = styled(Image)`
  ${css({
    borderRadius: '50px',
    objectFit: 'cover',
    width: ['95px', '95px', '95px', '72px'],
    height: ['95px', '95px', '95px', '72px'],
    mr: ['1em', '1em', '1em', '0']
  })}
`
export default CircleImage

import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { AlomovesSVG } from 'images/latest'

const AlomovesIcon = ({ color = 'black', width = '30px', height = '30px' }) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    fill={color}
    SVG={AlomovesSVG}
  />
)

export default AlomovesIcon

import React, { useEffect } from 'react'
import styled from 'styled-components'
import smoothscroll from 'smoothscroll-polyfill'

// This accounts for the sticky navbar when scrolling to the anchor
const Wrapper = styled('a')({
  position: 'absolute',
  marginTop: (props) => `-${props.theme.componentHeights.navbar.desktop}`
})

const useScrollAnchor = (id = 'ScrollAnchor') => {
  useEffect(() => {
    // scrollIntoView polyfill for Safari
    smoothscroll.polyfill()
  }, [])

  const scrollToAnchor = (e) => {
    if (e && typeof e.preventDefault === 'function') e.preventDefault()
    if (typeof window !== 'undefined') {
      window.history.pushState(null, null, `#${id}`) // add hash to window.location
    }
    const element = document.getElementById(id)
    if (element)
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
  }

  const ScrollAnchor = () => <Wrapper className='unstyled' name={id} id={id} />

  return {
    ScrollAnchor,
    scrollToAnchor
  }
}

export default useScrollAnchor

import {
  INIT_STATE,
  SET_PLANS,
  SET_SELECTED_PLAN,
  SET_PROMO_CODE,
  SET_SUCCESSFUL_SUBMIT
} from './actions'

import { findPlan } from './initializers/membership'

const reducer = (state, action) => {
  switch (action.type) {
    case INIT_STATE:
      return { ...state, ...action.payload }
    case SET_PLANS:
      return {
        ...state,
        membership: {
          ...state.membership,
          plans: action.payload
        }
      }
    case SET_PROMO_CODE: {
      let { plans, promoCode } = action.payload
      let selectedPlanError = null
      let selectedPlan = state.membership.selectedPlan

      if (promoCode && plans) {
        selectedPlan = findPlan(plans, selectedPlan.id)

        if (!selectedPlan.couponApplies) {
          selectedPlan = plans.find((plan) => plan.couponApplies)
          if (!selectedPlan) {
            // There is an error somewhere between the promo-code and the couponApplies property.
            // Gracefully fall back to first plan in array and alert user.
            selectedPlan = plans[0]
            selectedPlanError =
              'This promo code does not apply to the selected plan.'
          }
        }
      } else {
        // If no promoCode was supplied, set the plans to default.
        plans = state.membership.defaultPlans
        // Sometimes there are no defaultPlans (e.g. for_update returns null), in this case do nothing.
        if (plans)
          selectedPlan = plans.find(
            (plan) => plan.frequency === selectedPlan.frequency
          )
      }

      return {
        ...state,
        membership: {
          ...state.membership,
          plans,
          promoCode,
          selectedPlan,
          selectedPlanError
        }
      }
    }
    case SET_SELECTED_PLAN: {
      let selectedPlanError = null
      const selectedPlan = findPlan(state.membership.plans, action.payload)
      if (state.membership.promoCode && !selectedPlan.couponApplies) {
        selectedPlanError =
          'This promo code does not apply to the selected plan.'
      }
      return {
        ...state,
        membership: {
          ...state.membership,
          selectedPlan,
          selectedPlanError
        }
      }
    }
    case SET_SUCCESSFUL_SUBMIT:
      return {
        ...state,
        membership: {
          ...state.membership,
          successfulSubmit: action.payload
        }
      }
    default:
      return state
  }
}

export default reducer

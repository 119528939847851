import React from 'react'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Flex } from 'ui/acro'
import DrawerSectionHeader from './common/DrawerSectionHeader'
import DrawerResultTitleText from './common/DrawerResultTitleText'
import DrawerResultSubtitleText from './common/DrawerResultSubtitleText'
import { CircleImage } from './common'

const FlexWrap = styled(Flex)`
  ${css({
    flexWrap: 'wrap',
    width: '100%',
    gap: ['1em', '1em', '1em', '0']
  })}
`
const FlexBox = styled(Flex)`
  ${css({
    alignItems: 'center',
    flexDirection: ['row', 'row', 'row', 'column'],
    flex: [null, null, null, '1'],
    width: '100%',
    maxWidth: ['none', 'none', 'none', 'calc(100% / 3)']
  })}
`

const MemberResultItems = ({ data, value }) => {
  const memberData = data && data.users.top
  const count = data && data.users.resultCount

  if (memberData && memberData.length) {
    return (
      <FlexWrap>
        <DrawerSectionHeader
          title={'Members'}
          count={count}
          href={`/search?q=${value}&tab=members`}
          padding={'0 0 1em 0'}
          display={['none', 'none', 'none', 'flex']}
        />
        {memberData.slice(0, 3).map((item, idx) => (
          <FlexBox
            key={idx}
            id={'DrawerResultsMember' + (idx + 1)} // +1 so we don't start with position 0
            as='a'
            className='unstyled'
            href={item.url}
            cursor='pointer'
            onMouseDown={
              // Prevent onMouseDown event because onBlur is called on-mouse-down whereas click handler is on-mouse-up
              (e) => e.preventDefault()
            }
          >
            <CircleImage
              src={
                item.userProfileThumbnailImage === null
                  ? `https://d28gwrkukqy4h7.cloudfront.net/users/ico-profile-default.png`
                  : item.userProfileThumbnailImage
              }
            />
            <Flex flexDirection='column'>
              {item.name && (
                <DrawerResultTitleText
                  textAlign='center'
                  pt='.5em'
                  margin={[null, null, null, '2px 10px !important']}
                >
                  {item.name}
                </DrawerResultTitleText>
              )}
              <DrawerResultSubtitleText
                display={['flex', 'flex', 'flex', 'none']}
              >
                Member
              </DrawerResultSubtitleText>
            </Flex>
          </FlexBox>
        ))}
      </FlexWrap>
    )
  } else {
    return <></>
  }
}

export default MemberResultItems

import React from 'react'
import styled from 'styled-components'
import { P } from 'ui/acro'
import { H6 } from 'ui/bend'

const Main = styled.div`
  * {
    margin: 0;
    text-transform: none;
  }
`

const ZeroNavDesc = styled(P)`
  color: #3b3b3b;
  font-size: 0.875rem;
  letter-spacing: 0.01925rem;
`

const ZeroNavTitle = styled(H6)`
  color: #3b3b3b;
  font-size: 1rem;
  letter-spacing: 0.022rem;
`

const NoPracticeText = () => (
  <Main>
    <ZeroNavTitle>You haven't saved any classes or series yet</ZeroNavTitle>
    <ZeroNavDesc>
      Start adding classes & series to your practice to get started!
    </ZeroNavDesc>
  </Main>
)

export default NoPracticeText

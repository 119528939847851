import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { axios } from 'api'

export const Store = React.createContext(null)
export const useUserContext = () => useContext(Store)

//
// Wrap main component (e.g. TempEntry) in UserProvider for access to user in any component
// User JSON is passed in as a prop from the Rails view instead of being fetched from an API for performance/experience
//
export const UserProvider = ({ loadUser, user, children }) => {
  const [currentUser, setCurrentUser] = useState(user)
  const [loaded, setLoaded] = useState(user || !loadUser) // We only want children to load if there is a user or the user has loaded if requested.

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get('/user/profile')
        if (res) {
          setUser(res.data)
        }
      } catch (e) {
        // fail silently for now, we can institute a retry later.
        // We may also want to apply a redirect here in the future.
        // Though our controllers know user state before rendering pages that depend on it for access.
      } finally {
        setLoaded(true)
      }
    }

    if (loadUser) {
      fetchUser()
    }
  }, [loadUser])

  const setUser = ({ email, firstName, lastName, username, admin }) => {
    setCurrentUser((state) => ({
      ...state,
      email,
      firstName,
      lastName,
      username,
      admin
    }))
  }

  return (
    <Store.Provider value={{ user: currentUser, setUser }}>
      {loaded && children}
    </Store.Provider>
  )
}

UserProvider.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    hasSubscriptionAccess: PropTypes.bool
  }),
  children: PropTypes.node
}

/**
 * This function detects whether the user is on an iPhone or iPod. This will _not_ detect iPads.
 * Please be aware that iPad 13.0+ does not use iPad in its navigator.platform string.
 * 
 * @returns {boolean}
 */

const isiPhone = () => {
  return [
    'iPod Simulator',
    'iPhone Simulator',
    'iPod',
    'iPhone'
  ].includes(navigator.platform)
}

export default isiPhone
import React, { useContext, useReducer } from 'react'

import { axios } from 'api'

import reducer from './reducer'
import {
  initUpload,
  updateArrays,
  updateProgress,
  finishUploadFail,
  finishUploadSuccess,
  uploadRetry,
  updateChunkData
} from './actions'

const UploadActionStore = React.createContext(null)
export const useUploadActions = () => useContext(UploadActionStore)

const UploadStateStore = React.createContext(null)
export const useUploadState = () => useContext(UploadStateStore)

/**
 * The UploadProvider is responsible for managing multipart uploads as well as tracking their state locally.
 * It does so by providing two consumer hooks - `useUploadActions` and `useUploadState`
 *
 * `useUploadActions` provides an object of actions available to consumers. In this case, it is only one function - `uploadFile()`
 * `useUploadState` provides a single state object that consumers can destructure and utilize however they wish.
 *
 * The purpose of providing two contexts and consumers is to enable memoization of intermediary layers.
 * It may not realize any gains though, and we can easily convert to a single consumer hook model.
 */

const UploadProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {})

  const retryTimer = (delay) => {
    // setTimeout is non-blocking and immediately returns, so we can't await it.
    // Rather, we can return a promise that the function needs to await until it is done.
    return new Promise((res) => setTimeout(res, delay))
  }

  const checkIconCreated = async (id, retry = 20, delay = 1000) => {
    for (let i = 0; i < retry; i++) {
      try {
        const res = await axios.get(`uploads/${id}/as_icon`)
        if (res.status === 200) return res
        // res.status === 203 means that we're waiting.
      } catch (error) {
        if (error.response.status === 404) {
          throw new Error('There is no icon associated with this id.')
        }
      }
      await retryTimer(delay)
    }

    // We timed out.
    throw new Error(
      'There was an error converting your upload. Response timed out. Please contact engineering.'
    )
  }

  /**
   * Gets all the information necessary to split the file and then uploads each piece.
   * @param {string} id - Unique id to identify upload in state.
   * @param {Blob} file - File blob to upload
   * @param {Object} props
   * @param {Array} props.remainingParts - Array of parts to iterate and upload.
   * @param {string} props.uuid - The unique uuid of the upload, as defined by the server.
   */
  const uploadParts = async (id, file, { remainingParts = [], uuid }) => {
    try {
      for (let part of remainingParts) {
        // Get signed URL and indices needed to upload each part.
        const uploadUrl = await axios.put(`uploads/${uuid}/parts/${part}`)

        if (uploadUrl && uploadUrl.data) {
          const { url, startIndex, endIndex } = uploadUrl.data
          const chunk = file.slice(startIndex, endIndex)

          // Upload sliced chunk to signedUrl
          const chunkUploaded = await axios.put(`${url}`, chunk, {
            baseUrl: '',
            onUploadProgress: ({ loaded, total }) => {
              dispatch(updateProgress(id, loaded, total))
            }
          })

          if (chunkUploaded && chunkUploaded.headers.etag) {
            // Once S3 has the chunk, update our server of its success
            const updateServer = await axios.post(
              `uploads/${uuid}/parts/${part}`,
              { etag: chunkUploaded.headers.etag }
            )
            if (updateServer && updateServer.status === 200) {
              // We need to keep our state arrays updated to our percentage completed is correct.
              dispatch(updateArrays(id, part))
              if (updateServer.data.joinStarted) return uuid
            }
          }
        }
      }
    } catch (error) {
      throw error
    }
  }

  /**
   *
   * @param {Object} props
   * @param {string} props.id - The key to identify the upload within state.
   * @param {string} props.url - The url for the initial PUT request to initiate/resume an upload.
   * @param {Object} props.body - Any additional information required in the body beyond name and size.
   * @param {Object} props.axiosOptions - Any options to pass through to axios (e.g. { baseUrl })
   * @param {number} props.retry - Number of times to retry. Defaults to 10.
   * @param {number} props.delay - Time in milliseconds for delay. Note, this is increased exponentially. Defaults to 2000
   *
   * @return {string} uuid - the UUID of the upload
   */
  const uploadFile = async ({
    id,
    url,
    file,
    body,
    axiosOptions,
    uploadCallback,
    retry = 10,
    delay = 2000
  }) => {
    // We can refactor this out into a HOC or reusable component if we find ourselves needing retry/delays elsewhere.
    // We initUpload here so we don't reinstantiate the whole thing on every loop.
    // This creates an entry in state with id as the key.
    dispatch(initUpload(id))
    for (let i = 0; i < retry; i++) {
      try {
        const formattedBody = {
          ...body,
          filename: file.name,
          file_size: file.size
        }

        const chunks = await axios.put(url, formattedBody, axiosOptions)
        if (chunks) {
          if (chunks.data.resumed && chunks.data.remainingParts === 0) {
            // This was a resumed upload and there are no remaining parts to upload.
            if (uploadCallback && typeof uploadCallback === 'function') {
              await uploadCallback(chunks.data.uuid)
            }
            dispatch(finishUploadSuccess(id))
            return chunks.data.uuid
          }

          if (chunks.data.remainingParts.length > 0) {
            dispatch(updateChunkData(id, chunks.data)) // Add upload information to state[id]
            const res = await uploadParts(id, file, chunks.data)
            if (res) {
              if (uploadCallback && typeof uploadCallback === 'function') {
                await uploadCallback(res)
              }
              dispatch(finishUploadSuccess(id))
              return res
            }
          }

          throw Error('Resumed upload with no remaining pieces to upload.')
        }
      } catch (error) {
        const isLastAttempt = i + 1 === retry
        if (isLastAttempt) {
          dispatch(
            finishUploadFail(
              id,
              'We apologize, but there has been an issue uploading this file. Please try again later.'
            )
          )
          throw error
        } else {
          let errorMessage =
            'We apologize, but there has been a issue uploading this file.'

          if (error.response) {
            if (error.response.status === 422) {
              if (error.response.data && error.response.data.error) {
                dispatch(finishUploadFail(id, error.response.data.error + '.'))
              }
              throw error
            }

            if (error.response.status === 500) {
              // A server is borked. Abort!
              dispatch(finishUploadFail(id, 'The server could not be reached.'))
              throw error
            }
            if (error.response.status === 404) {
              errorMessage = 'We were unable to reach the upload servers.'
            }
          }
          dispatch(uploadRetry(id, errorMessage))
          await retryTimer(delay * Math.pow(2, Math.min(i, 6))) // exponential delay with a cap.
        }
      }
    }
  }

  return (
    <UploadActionStore.Provider value={{ checkIconCreated, uploadFile }}>
      <UploadStateStore.Provider value={state}>
        {children}
      </UploadStateStore.Provider>
    </UploadActionStore.Provider>
  )
}

export default UploadProvider

import React from 'react'
import PropTypes from 'prop-types'

import { Caption } from 'ui/acro'

const FooterLink = ({ external, children, ...rest }) => (
  <Caption
    variant='bold'
    as='a'
    target={external && '_blank'}
    rel={external && 'noopener'}
    className='unstyled'
    mx={['10px', '18px']}
    mb={['12px', null, '0px']}
    {...rest}
  >
    {children}
  </Caption>
)

FooterLink.propTypes = {
  external: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default FooterLink

import React from 'react'

import { Flex, P } from 'ui/acro'
import FooterLink from './FooterLink'
import styled from 'styled-components'
import css from '@styled-system/css'
import { AppleStoreBadge } from 'images'
import { AlomovesIcon } from 'components_v2/Icons'
import { gift_card_interest_event } from 'gtm/pushEvents'

const DownloadText = styled.div`
  ${css({
    fontFamily: 'Proxima-nova',
    width: ['70%', '70%', '23%', '23%'],
    display: 'flex',
    flexDirection: 'column',
    marginTop: [null, , '1em', '3em'],
    alignItems: ['center', 'center', 'flex-start', 'flex-start']
  })}
`
const FooterLinks = () => {
  return (
    <Flex
      justifyContent='center'
      flexWrap={['wrap-reverse', 'wrap-reverse', 'wrap', 'wrap', 'wrap']}
      alignItems='baseline'
      px='20px'
      mb={[null, '5px', '30px']}
      mt={['6em', '6em', '8em']}
    >
      <DownloadText>
        <AlomovesIcon
          width={['100px', '130px', '160px']}
          height={['40px', '40px', '70px']}
        />
        <P
          mb='10px'
          fontSize={['12px', '13px', '13px', '15px']}
          textAlign={['center', 'center', 'left']}
        >
          Download the <strong>Alo Moves app</strong> to take classes offline
          and practice anytime, anywhere.
        </P>
        <a
          target='_blank'
          href='https://apps.apple.com/us/app/alo-moves/id601617403'
          rel='noreferrer'
        >
          <AppleStoreBadge width='130px' />
        </a>
      </DownloadText>
      <Flex flexWrap='wrap' justifyContent='center'>
        <FooterLink href='/support' id='footer_faq'>
          FAQ
        </FooterLink>
        <FooterLink
          href='https://www.alomoves.com/contact-us'
          id='footer_contact'
          external
        >
          Contact Us
        </FooterLink>
        <FooterLink href='/share?traffic_source=site_footer' id='footer_share'>
          Refer
        </FooterLink>
        <FooterLink
          href='/gift?utm_source=footer'
          onClick={() => gift_card_interest_event('footer')}
          id='footer_giftcard'
        >
          Gift Cards
        </FooterLink>
        <FooterLink href='https://blog.alomoves.com' id='footer_blog' external>
          Blog
        </FooterLink>
        <FooterLink
          href='https://boards.greenhouse.io/aloyoga'
          id='footer_careers'
          external
        >
          Careers
        </FooterLink>
        <FooterLink href='https://www.aloyoga.com' id='footer_alo' external>
          Alo Yoga
        </FooterLink>
      </Flex>
    </Flex>
  )
}

export default FooterLinks

import { useEffect, useState } from 'react'

import useDebounce from './useDebounce'
import useFetchAPI from './useFetchAPI'

const SEARCH_URL = '/search/results'

const useSearchAPI = (props) => {
  const [value, setValue] = useState('')
  const [{ data }, setUrl] = useFetchAPI(null)
  const debouncedValue = useDebounce(value, 250)

  const handleBlur = () => {
    setValue('')
    setUrl(null)
  }

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // If there is nothing to search for, don't bother.
    if (debouncedValue.length === 0) return

    // Reload the page if we are already on the search page
    const reload = window.location.href.indexOf('/search/results') !== -1
    window.location.href = `/search?q=${value}`

    if (reload) window.location.reload()
  }

  useEffect(() => {
    if (debouncedValue.length < 3) {
      // Setting url to null will clear results by setting data to null (see useFetchAPI() for details)
      setUrl(null)
    } else {
      const querySearch = (value) => {
        setUrl(`${SEARCH_URL}?q=${value}`)
      }
      querySearch(debouncedValue)
    }
  }, [debouncedValue, setUrl])

  return {
    value,
    debouncedValue,
    data,
    handleBlur,
    handleChange,
    handleSubmit
  }
}

export default useSearchAPI

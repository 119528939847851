import React from 'react'
import { Flex } from 'ui/acro'
import DrawerSectionHeader from './common/DrawerSectionHeader'
import styledComponents from 'styled-components'
import { css } from '@styled-system/css'
import ClassResultItem from './common/ClassResultItem'

const FlexBox = styledComponents(Flex)`
${css({
  flexDirection: 'column',
  gap: '1em'
})}
`

const ClassResultItems = ({ data, value }) => {
  const classData = data && data.classes.top
  const count = data && data.classes.resultCount

  if (classData && classData.length) {
    return (
      <FlexBox>
        <DrawerSectionHeader
          title={'Classes'}
          count={count}
          href={`/search?q=${value}&tab=classes`}
          padding={'0 0 .4375em 0'}
          display={['none', 'none', 'none', 'flex']}
        />
        {classData.slice(0, 3).map((item, idx) => (
          <ClassResultItem
            key={idx}
            id={'DrawerResultsClass' + (idx + 1)} // +1 so we don't start with position 0
            href={item.url}
            thumbnailImage={item.thumbnailImage}
            title={item.title}
            humanDuration={item.humanDuration}
            coaches={item.coaches}
            category={item.category}
            subcategory={item.subcategory}
            difficultyLevel={item.difficultyLevel}
          />
        ))}
      </FlexBox>
    )
  } else {
    return <></>
  }
}

export default ClassResultItems

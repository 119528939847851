import React from 'react'
import PropTypes from 'prop-types'

import ButtonBase from './ButtonBase'
import { ResponsiveSVG } from 'ui/acro/elements'
import { LeftArrow } from 'images/latest'

const PrevButton = ({ handleClick, fill = 'primary', ...props }) => (
  <ButtonBase
    {...props}
    onClick={handleClick}
    onKeyPress={handleClick}
    tabIndex='0'
    id='carousel-back-button'
    aria-label='carousel-back-button'
  >
    <ResponsiveSVG height='100%' width='100%' fill={fill} SVG={LeftArrow} />
  </ButtonBase>
)

PrevButton.defaultProps = {
  top: '40%',
  left: '-25px'
}

PrevButton.propTypes = {
  handleClick: PropTypes.func.isRequired
}

export default PrevButton

const GEOLOCATION_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://geolocation.alo.software'
    : 'https://geolocation.dev.alo.software'

export const getLocation = async () => {
  try {
    const response = await fetch(GEOLOCATION_URL)
    const country = await response.json()
    return country.country_code ? country.country_code : null
  } catch (error) {
    console.error(error)
    return null
  }
}

export default getLocation

import styled from 'styled-components'
import { css } from '@styled-system/css'
import { P } from 'ui/acro'

const ClassCountBadge = styled(P)(() =>
  css({
    bg: 'black',
    color: 'white',
    fontFamily: 'Arquitecta',
    wordBreak: 'none',
    fontSize: '13px',
    position: 'absolute',
    padding: '0px 5px',
    textTransform: 'uppercase',
    margin: '0' // overwrite legacy p margin in application.css
  })
)

export default ClassCountBadge

import { useState } from 'react'

// TODO Accessibility: we should move any elements used during a toggleLock() to their own Portal so we can add aria-hidden to <body> during a lock.

const useBodyScrollLock = () => {
  const [locked, setLocked] = useState()

  const toggleBodyLock = () => {
    if (!locked) {
      document.body.classList.add('prevent-scroll')
      setLocked(true)
    } else {
      document.body.classList.remove('prevent-scroll')
      setLocked(false)
    }
  }

  return toggleBodyLock
}

export default useBodyScrollLock

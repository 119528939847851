import * as Yup from 'yup'

/***
 * This function builds a Yup validator object.
 * It allows users to provide a validation type and list of validation methods which will chain together on top of the validation type.
 * NOTE: this will only build a single, top-level validator. It does not currently traverse to construct nested validators for objects or arrays.
 *
 * @type {array}
 *
 * @param {String} validationType -- the type of validation to build upon (e.g. 'string' | 'boolean'). For valid validation types, see https://github.com/jquense/yup
 * @param {String} validationTypeError -- optional string that will be returned as an error if an invalid type is supplied for validation.
 * @param {Object[]} validations -- An array of valid validations used to build validator chain.
 * @param {string} validations[].type -- The method to add to a validator array.
 * @param {any} validations[].params -- Any auxiliary params to feed into the validator method as args. The shape of this is dependent upon what the validations[].type method accepts.
 *
 * @returns {Object} -- Returns a valid Yup validator object - e.g. Yup.string().max(30, 'error message for max').required('String is required')
 */
const buildYupValidator = ({ validationType, validations }) => {
  // There is an invalid validationType on this field. Ignore it. We might want to throw here(?)
  if (!Yup[validationType]) return null

  let validator = Yup[validationType]()

  validations.forEach((validation) => {
    const { type, params } = validation

    if (!validator[type]) {
      // this method does not exist on the given validator (e.g. trying to add .min() to a Yup.boolean() call)
      return
    }
    if (type === 'matches') {
      // If we are doing regexp matching, we need to coerce the given string (params[0]) into a Regex object.
      const [regexp, ...rest] = params
      validator = validator[type](new RegExp(regexp), ...rest)
    } else {
      // params is an array that we can spread out into the type, allowing us to pass in error messages, etc.
      validator = validator[type](...params)
    }
  })

  return validator
}

export default buildYupValidator

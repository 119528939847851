import React, { Fragment } from 'react'
import styled from 'styled-components'

import { useUserContext } from 'context'
import { Caption, Flex, HR, ResponsiveSVG } from 'ui/acro'

import { useNavbarContext } from '../../../common/data/_store'
import AppStoreComponent from '../../../common/AppStoreComponent'

import NotificationBadge from './NotificationBadge'
import { gift_card_interest_event } from 'gtm/pushEvents'
import { AloMovesLogo } from 'images/latest'

const ListItem = styled(Caption)({
  position: 'relative',
  paddingTop: '10px',
  paddingBottom: '10px',
  fontSize: '15px',
  whiteSpace: 'nowrap',
  fontWeight: 900
})

// Legacy hack alert!
// If specifying 'color' prop on anchor tags in the nav or footer on legacy pages, it will be overriden by 'color: inherit' (see ThemeWrapper)
// Using &&& increases specificity by applying the class three times, making it take precedence
const GreyListItem = styled(ListItem)`
  &&& {
    color: ${(props) => props.theme.colors.grey4};
  }
`

// ListItem for name is a <div/> so that legacy application.css won't try to style it (renders as h4 by default)

const ProfileMenu = () => {
  const { user } = useUserContext()
  const { state, actions } = useNavbarContext()

  const handleMenuClick = (item) => {
    item?.name?.toLowerCase() === 'gift cards' &&
      gift_card_interest_event(user?.firstName ? 'si_nav' : 'so_nav')
  }

  return (
    <Flex flexDirection='column'>
      {user.firstName && (
        <Fragment>
          <GreyListItem as='div' variant='bold' pt='0px'>
            {user.firstName}
          </GreyListItem>
          <HR my='10px' />
        </Fragment>
      )}
      <ListItem
        id='navbar-my-profile-link'
        as='a'
        href={`/users/${user.username}`}
        className='unstyled'
        variant='medium'
      >
        My Profile
      </ListItem>
      <ListItem
        as='a'
        href='/user/playlists'
        className='unstyled'
        variant='medium'
      >
        My Playlists
      </ListItem>
      <ListItem
        as='a'
        href='/notifications'
        onClick={actions.clearNotifications}
        className='unstyled'
        variant='medium'
      >
        Notifications
        {state.notificationCount > 0 && <NotificationBadge ml='10px' />}
      </ListItem>
      <ListItem as='a' href='/community' className='unstyled' variant='medium'>
        Community
      </ListItem>
      <ListItem
        as='a'
        href='/settings/profile'
        className='unstyled'
        variant='medium'
      >
        Settings
      </ListItem>

      <HR my='10px' />

      <ListItem
        as='a'
        href='/alo_shop_si_web'
        className='unstyled'
        variant='medium'
        id='alo_shop_si_web'
        rel='noopener noreferrer'
        target='_blank'
      >
        Shop
        <ResponsiveSVG
          marginLeft={'0.5rem'}
          SVG={AloMovesLogo}
          width='1.7rem'
          height='1.5rem'
        />
      </ListItem>

      <ListItem
        as='a'
        href='/gift?utm_source=si_nav'
        className='unstyled'
        variant='medium'
      >
        gift cards
      </ListItem>

      <HR my='10px' />

      <ListItem
        as='a'
        href='/referrals?traffic_source=site_menu'
        className='unstyled'
        variant='medium'
      >
        Refer A Friend
      </ListItem>
      <ListItem as='a' href='/support' className='unstyled' variant='medium'>
        Get Help
      </ListItem>
      <ListItem
        as='a'
        href='/signout'
        id='nav_logout_desktop'
        className='unstyled'
        variant='medium'
        pb='0px'
      >
        sign out
      </ListItem>
      <HR my='10px' />
      {/* <ListItem> */}
      <AppStoreComponent />
      {/* </ListItem> */}
    </Flex>
  )
}

ProfileMenu.propTypes = {}

export default ProfileMenu

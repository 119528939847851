import React from 'react'
import PropTypes from 'prop-types'

import ButtonBase from './ButtonBase'
import { ResponsiveSVG } from 'ui/acro/elements'
import { RightArrow } from 'images/latest'

const NextButton = ({ handleClick, fill = 'primary', ...props }) => (
  <ButtonBase
    {...props}
    onClick={handleClick}
    onKeyPress={handleClick}
    tabIndex='0'
    id='carousel-next-button'
    aria-label='carousel-next-button'
  >
    <ResponsiveSVG height='100%' width='100%' fill={fill} SVG={RightArrow} />
  </ButtonBase>
)

NextButton.defaultProps = {
  top: '40%',
  right: '-25px'
}

NextButton.propTypes = {
  handleClick: PropTypes.func.isRequired
}

export default NextButton

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Image as ImageAcro } from 'ui/acro'
import { TimeCodeBadge } from 'components_v2/Home/SignedIn/common/components'
import {
  SearchCardSubitle,
  SearchCardTitle
} from '../common/SearchCardTextStyles'
import handleVideoClick from 'helpers/ga4Proxy'

const ClassResultCard = forwardRef(
  (
    {
      carouselItem,
      title,
      htmlId,
      url,
      thumbnailImage,
      coaches,
      category,
      subcategory,
      difficultyLevel,
      humanDuration,
      classesCount,
      altText,
      id,
      trackGa4,
      position
    },
    ref
  ) => {
    return (
      <Flex
        id={htmlId}
        ref={ref}
        flexDirection='column'
        position='relative'
        className={carouselItem ? 'swiper-slide unstyled' : 'unstyled'}
        as='a'
        href={url}
        onClick={() =>
          trackGa4 && handleVideoClick(id, 'PlanEntry', position, url)
        }
      >
        <Box position='relative' mb={['8px', '10px']}>
          <ImageAcro
            src={thumbnailImage}
            alt={altText}
            objectFit='cover'
            onError={(elem) => {
              elem.currentTarget.src =
                'https://alomoves.s3.amazonaws.com/manual_uploads/shared/search/placeholder-cards/rectangle-card.jpg'
            }}
          />
          {(humanDuration || classesCount) && (
            <TimeCodeBadge
              fontSize='13px !important'
              bottom='16px !important'
              left='16px !important'
              backgroundColor={classesCount && 'black !important'}
              color={classesCount && 'white !important'}
            >
              {humanDuration || `${classesCount} Classes`}
            </TimeCodeBadge>
          )}
        </Box>
        {title && (
          <SearchCardTitle id='ClassTitle' className='title' as='p'>
            {title}
          </SearchCardTitle>
        )}
        {coaches && (
          <SearchCardSubitle id='ClassSubtitle' className='subtitle' as='p'>
            {coaches.map((item, idx) => (idx ? ', ' : '') + item)}
          </SearchCardSubitle>
        )}
        <SearchCardSubitle className='subtitle' as='p'>
          {category && `${category}`}
          {subcategory && ` / ${subcategory}`}
          <span style={{ textTransform: 'capitalize' }}>
            {' '}
            {difficultyLevel && ` • ${difficultyLevel}`}
          </span>
        </SearchCardSubitle>
      </Flex>
    )
  }
)

ClassResultCard.propTypes = {
  title: PropTypes.string.isRequired,
  htmlId: PropTypes.any,
  url: PropTypes.string.isRequired,
  thumbnailImage: PropTypes.string,
  coaches: PropTypes.array.isRequired,
  category: PropTypes.string,
  subcategory: PropTypes.string,
  humanDuration: PropTypes.string,
  difficultyLevel: PropTypes.string,
  altText: PropTypes.string
}

export default ClassResultCard

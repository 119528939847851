import { useEffect, useState } from 'react'
import { getLocation } from 'helpers/getLocation'

const useGetLocation = () => {
  const [country, setCountry] = useState('')

  useEffect(() => {
    if (!country) {
      getLocation().then((res) => {
        setCountry(res)
      })
    }
  }, [])

  return country
}

export default useGetLocation

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import isWebView from 'is-ua-webview'
import { useUserContext } from 'context'
import { Flex, Box, componentHeights as heights } from 'ui/acro'
import { useBodyScrollLock, useWindowResize } from 'hooks'
import RightContent from './Menus/Desktop/RightContent/RightContent'
import MobileMenu from './Menus/Mobile/MobileMenu'
import MobileSearch from './Menus/Desktop/RightContent/Search/MobileSearch'
import MobileMenuContainer from './Menus/Mobile/MobileMenuContainer'
import Logo from './Logo'
import { NavbarStoreProvider } from './Menus/common/data/_store'
import DesktopMiddleNavContent from './Menus/Desktop/MiddleContent/MiddleNavContent'

export const StickyNavbar = styled(Flex)((props) =>
  css({
    position: 'sticky',
    top: 0,
    zIndex: 1002, // Existing CSS defines many z-indexes as 1000+
    height: [heights.navbar.mobile, null, heights.navbar.desktop],
    background: props.theme.colors.base,
    boxShadow:
      '0px 1px 4px rgba(0, 0, 0, 0.06), 0px 8px 16px rgba(35, 41, 54, 0.08)',
    px: ['11px', null, '20px'],
    transition: 'background 0.25s ease-in-out'
  })
)

const LogoContainer = styled(Flex)`
  ${css({
    justifyContent: ['center', 'center', 'center', 'start'],
    zIndex: ['2', '2', '2', '3']
  })}
`

const NavBarRightSection = styled.div`
  display: flex;
  gap: 2rem;
  align-items: ${(props) => (props.isLogin ? 'baseline' : 'end')};
`

// TODO: Conditionally render signed in/out nav based on user status
const Navbar = ({ links, auth }) => {
  const { user } = useUserContext()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const showLeft = links
  const toggleBodyLock = useBodyScrollLock()

  const toggleMenu = () => {
    // This will keep the background from scrolling when a user has the mobile navbar menu open.
    toggleBodyLock()

    setMenuIsOpen((state) => !state)
  }

  const [size, setSize] = useState()
  const { width } = useWindowResize()
  useEffect(() => {
    setSize(width < 1200 ? 'mobileMenu' : 'desktopMenu')
  }, [width])
  const isDesktop = size === 'desktopMenu'

  const ua = navigator.userAgent
  //////////////////////////////////////////////////

  return (
    <NavbarStoreProvider>
      {/*
        Legacy pages:
        - id ReactNavbar is for fixed positioning in components_v1/StyledHeader
        - className js-navbar is for querySelector call in HelpPopup
      */}
      <StickyNavbar
        id='ReactNavbar'
        className='js-navbar'
        alignItems='center'
        justifyContent='space-between'
      >
        {/*
          The below is just the container that houses the hamburger menu icon, 
          the actual mobile menu is rendered down on line 108 (this needs to be reworked, it's confusing)
        */}
        <MobileMenuContainer toggleMenu={toggleMenu} menuIsOpen={menuIsOpen} />
        <NavBarRightSection isLogin={user}>
          <LogoContainer>
            <Logo color='black' />
          </LogoContainer>
          {showLeft && isDesktop && <DesktopMiddleNavContent />}
        </NavBarRightSection>
        {!isWebView(ua) && isDesktop && (
          <RightContent
            links={links}
            auth={auth}
            color={'black'}
            toggleMenu={toggleMenu}
            menuIsOpen={menuIsOpen}
          />
        )}
        {!isWebView(ua) && !isDesktop && user ? (
          <Box padding='4px'>
            <MobileSearch />
          </Box>
        ) : (
          !user &&
          !isDesktop && (
            <Box padding='4px'>
              <Flex padding='10px'> </Flex>
            </Box>
          )
        )}
      </StickyNavbar>
      <MobileMenu open={menuIsOpen} onToggle={toggleMenu} />
    </NavbarStoreProvider>
  )
}

Navbar.propTypes = {
  links: PropTypes.bool,
  auth: PropTypes.bool
}

export default Navbar

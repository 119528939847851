import initializers, { customFieldsInitializer } from './initializers'
import { initState } from './actions'

const initializer = async ({ dispatch, forms, options }) => {
  // This will give us access to the forms used to instantiate the Subscribe Engine, helpful for conditional submissions.
  let initialState = {
    forms
  }

  // Provide a top-level error handler that hooks into formik and sorts out errors accordingly.
  let actions = {
    handleError: (error, { setStatus, setFieldError }) => {
      if (error.response && error.response.data && error.response.data.error) {
        setStatus(error.response.data.error)
      } else if (error.field) {
        setFieldError(error.field, error.message)
      } else if (error.message) {
        setStatus(error.message)
      } else {
        setStatus(error)
      }
    }
  }

  // We iterate through the forms array and hydrate our values by creating composite objects.
  let initialValues, validators, loadError

  try {
    // Iterate through initializer functions to recieve their promises (some have API calls in init -- e.g. payment receives card info)
    const initializerPromises = forms.map((form) => {
      if (form === 'customFields') {
        // If a custom field is provided, we must use the options.customFields.fields array to construct the object.
        // This will allow the API or consumer to construct a novel set of values and validators on the fly.
        // We may want to abstract this even more so that the consumer/API can request multiple rounds of custom fields, but that's a tomorrow problem (sorry future Dev).
        if (
          options.offer &&
          options.offer.customFields &&
          options.offer.customFields.fields
        ) {
          return customFieldsInitializer(options.offer.customFields.fields)
        } else {
          throw new Error(
            'You must supply a fields for custom fields in the form of options.customFields.fields.'
          )
        }
      } else if (typeof initializers[form] === 'function') {
        return initializers[form](dispatch, { ...options })
      } else {
        return {} // maybe turn into error throw?
      }
    })

    // Resolve all of our initializer promises.
    const resolvedInitializers = await Promise.all(initializerPromises)

    // Iterate over resolved promises and extract the returned values from each one.
    resolvedInitializers.forEach((res) => {
      if (res.values) initialValues = { ...initialValues, ...res.values }
      if (res.state) initialState = { ...initialState, [res.type]: res.state }
      if (res.validators)
        validators = { ...validators, [res.type]: res.validators }
      if (res.actions) actions = { ...actions, [res.type]: res.actions }
      if (res.loadError) loadError = res.loadError
    })

    if (initialState) {
      dispatch(initState(initialState))
    }
  } catch (error) {
    if (error.message) {
      loadError = error.message
    } else {
      loadError = 'Something went wrong with the Subscribe Engine.'
    }
  }

  return { actions, initialValues, validators, loadError }
}

export default initializer

import { css } from '@styled-system/css'
import {
  CardSubtitle,
  CardTitle
} from 'components_v2/Home/SignedIn/common/styles'
import styledComponents from 'styled-components'

export const SearchCardTitle = styledComponents(CardTitle)`
${css({
  fontSize: ['14px', '16px'],
  lineHeight: 'normal',
  pb: '6px'
})}
`
export const SearchCardSubitle = styledComponents(CardSubtitle)`
${css({
  fontSize: ['12px', '14px'],
  lineHeight: '16.1px'
})}`

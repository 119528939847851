export const yogaCategories = {
  category: 'Yoga',
  seriesExploreLink: '/explore/filter?categories=yoga&order=date',
  subcategories: [
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Ashtanga.jpg',
      title: 'Ashtanga',
      url: '/explore/filter?categories=yoga_ashtanga&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Hatha.jpg',
      title: 'Hatha',
      url: '/explore/filter?categories=yoga_hatha&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Kids-Yoga.jpg',
      title: 'Kids Yoga',
      url: '/explore/filter?categories=yoga_kids-yoga&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Kundalini.jpg',
      title: 'Kundalini',
      url: '/explore/filter?categories=yoga_kundalini&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Prenatal.jpg',
      title: 'Prenatal/Postnatal',
      url: '/explore/filter?categories=yoga_prenatal-postnatal&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Restorative.jpg',
      title: 'Restorative',
      url: '/explore/filter?categories=yoga_restorative&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Vinyasa.jpg',
      title: 'Vinyasa',
      url: '/explore/filter?categories=yoga_vinyasa&amp;order=date'
    }
  ]
}

export const fitnessCategories = {
  category: 'Fitness',
  seriesExploreLink: '/explore/filter?categories=fitness&order=date',
  subcategories: [
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Barre.jpg',
      title: 'Barre',
      url: '/explore/filter?categories=fitness_barre&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Core.jpg',
      title: 'Core',
      url: '/explore/filter?categories=fitness_core&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Dance.jpg',
      title: 'Dance',
      url: '/explore/filter?categories=fitness_dance&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Gym.jpg',
      title: 'Gym',
      url: '/explore/filter?categories=fitness_gym&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_HIIT.jpg',
      title: 'HIIT',
      url: '/explore/filter?categories=fitness_hiit&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Pilates.jpg',
      title: 'Pilates',
      url: '/explore/filter?categories=fitness_pilates&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Prenatal.jpg',
      title: 'Prenatal/Postnatal',
      url: '/explore/filter?categories=fitness_prenatal-postnatal&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Running.jpg',
      title: 'Running',
      url: '/explore/filter?categories=fitness_running&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Sculpt.jpg',
      title: 'Sculpt',
      url: '/explore/filter?categories=fitness_sculpt&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Strength.jpg',
      title: 'Strength',
      url: '/explore/filter?categories=fitness_strength&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Stretching.jpg',
      title: 'Stretching',
      url: '/explore/filter?categories=fitness_stretching&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Walking.jpg',
      title: 'Walking',
      url: '/explore/filter?categories=fitness_walking&amp;order=date'
    }
  ]
}

export const mindfulnessCategories = {
  category: 'Mindfulness',
  seriesExploreLink: '/explore/filter?categories=mindfulness&order=date',
  subcategories: [
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Breathwork.jpg',
      title: 'Breathwork',
      url: '/explore/filter?categories=mindfulness_breathwork&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Meditation.jpg',
      title: 'Meditation',
      url: '/explore/filter?categories=mindfulness_meditation&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Reiki.jpg',
      title: 'Reiki',
      url: '/explore/filter?categories=mindfulness_reiki&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Sound.jpg',
      title: 'Sound',
      url: '/explore/filter?categories=mindfulness_sound&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Yoga+Nidra.jpg',
      title: 'Yoga Nidra',
      url: '/explore/filter?categories=mindfulness_yoga-nidra&amp;order=date'
    }
  ]
}

export const wellnessCategories = {
  category: 'Wellness',
  seriesExploreLink: '/explore/filter?categories=wellness&order=date',
  subcategories: [
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/CycleSyncing.jpg',
      title: 'Cycle Syncing',
      url: '/explore/filter?categories=wellness_cycle-syncing&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/Nutrition.jpeg',
      title: 'Nutrition',
      url: '/explore/filter?categories=wellness_nutrition&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/Personal-Growth.jpg',
      title: 'Personal Growth',
      url: '/explore/filter?categories=wellness_personal-growth&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/SelfCare.jpg',
      title: 'Self-Care',
      url: '/explore/filter?categories=wellness_self-care&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/Sleep.jpg',
      title: 'Sleep',
      url: '/explore/filter?categories=wellness_sleep&amp;order=date'
    }
  ]
}

export const skillsCategories = {
  category: 'Skills',
  seriesExploreLink: '/explore/filter?categories=skills&order=date',
  subcategories: [
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Arm-Balances.jpg',
      title: 'Arm Balances',
      url: '/explore/filter?categories=skills_arm-balances&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Backbends.jpg',
      title: 'Backbends',
      url: '/explore/filter?categories=skills_backbends&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Flexibility.jpg',
      title: 'Flexibility',
      url: '/explore/filter?categories=skills_flexibility&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__How-To-Teach.jpg',
      title: 'How to Teach',
      url: '/explore/filter?categories=skills_how-to-teach&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Inversion.jpg',
      title: 'Inversions',
      url: '/explore/filter?categories=skills_inversions&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Mobility.jpg',
      title: 'Mobility',
      url: '/explore/filter?categories=skills_mobility&amp;order=date'
    },
    {
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Yoga-Poses.jpg',
      title: 'Yoga Poses',
      url: '/explore/filter?categories=skills_yoga-poses&amp;order=date'
    }
  ]
}

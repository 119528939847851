import React from 'react'

import { HorizontalLogo } from 'images'
import { ResponsiveSVG } from 'ui/acro'

const Logo = ({ color = 'primary' }) => (
  <ResponsiveSVG
    maxWidth={['142px', '142px', '165px']}
    as='a'
    href='/'
    className='unstyled'
    aria-label='Navigate to Alo Moves Home Page'
    id='nav_logo'
    fill={color}
    SVG={HorizontalLogo}
  />
)

export default Logo

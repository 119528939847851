import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { Twinkle } from 'images/latest'

const TwinkleIcon = ({ color }) => (
  <ResponsiveSVG
    width='20px'
    height='20px'
    className='unstyled twinkle-icon'
    color={color}
    SVG={Twinkle}
  />
)

export default TwinkleIcon

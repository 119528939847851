import axios from './axios.config'
import { useQuery } from 'react-query'

const getMembershipPlans = async () => {
  const { data } = await axios.get('/membership_plans', {
    params: { v: '1' }
  })
  return data
}

export const useGetMembershipPlans = () =>
  useQuery('getMembershipPlans', getMembershipPlans)

import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

const EmbedContext = createContext(null)
export const useEmbedContext = () => useContext(EmbedContext)

const EmbedProvider = ({ children }) => {
  // Context: Android currently embeds WebViews for the /subscribe flow
  const params = new URLSearchParams(window.location.search)
  const embed = params.get('embed') === 'true'

  return <EmbedContext.Provider value={embed}>{children}</EmbedContext.Provider>
}

EmbedProvider.propTypes = {
  children: PropTypes.node
}

export default EmbedProvider

export const SET_MODAL_START = 'SET_MODAL_START'
export const SET_MODAL_FINISH = 'SET_MODAL_FINISH'
export const CLOSE_MODAL_START = 'CLOSE_MODAL_START'
export const CLOSE_MODAL_FINISH = 'CLOSE_MODAL_FINISH'
export const CHANGE_TITLE = 'CHANGE_TITLE'

export const setModalStart = (Modal, options = {}) => ({
  type: SET_MODAL_START,
  Modal,
  options
})

export const setModalFinish = () => ({
  type: SET_MODAL_FINISH
})

export const closeModalStart = () => ({
  type: CLOSE_MODAL_START
})

export const closeModalFinish = () => ({
  type: CLOSE_MODAL_FINISH
})

export const changeTitle = (title) => ({
  type: CHANGE_TITLE,
  title
})

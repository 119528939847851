import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { CommunityGym } from 'images/latest'

const CommunityGymIcon = ({ color = 'black' }) => (
  <ResponsiveSVG
    width='27px'
    height='33px'
    className='unstyled'
    fill={color}
    SVG={CommunityGym}
  />
)

export default CommunityGymIcon

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Box, Flex, Image, NameList } from 'ui/acro'
import TimeCodeBadge from './TimeCodeBadge'
import styled from 'styled-components'
import { CardSubtitle, CardTitle } from '../styles'

const CircleImage = styled(Image)({
  height: '100%',
  objectFit: 'cover',
  borderRadius: '50%',
  top: '0',
  left: '0'
})

const ZenCard = forwardRef(
  (
    { carouselItem, humanDuration, videoThumbnail, title, planEntryUrl, plan },
    ref
  ) => {
    // Our text is styled as='div' because this card is rendering in the Signed In Navbar, which loads application.css. This causes weird spacing for h4 and p elements.
    const sizeArray = ['120px', '170px', '180px', '214px']
    return (
      <Flex
        id='ZenCard'
        ref={ref}
        flexDirection='column'
        position='relative'
        className={carouselItem ? 'swiper-slide unstyled' : 'unstyled'}
        as='a'
        href={planEntryUrl}
      >
        {videoThumbnail && (
          <Box position='relative' width={sizeArray} height={sizeArray}>
            <CircleImage src={videoThumbnail} />
            <Box position='relative' textAlign='center'>
              <TimeCodeBadge
                position='inherit'
                display='inline'
                padding='5px'
                top={['-13px', null, null, '-15px']}
                fontSize={['13px', null, null, '16px']}
                boxShadow={`0px 4px 12px 0px #23293614, 0px 2px 6px 0px #0000000F`}
              >
                {humanDuration}
              </TimeCodeBadge>
            </Box>
          </Box>
        )}
        <Box width={sizeArray}>
          {title && (
            <CardTitle id={'DailyZenClassName'} pt='20px' textAlign='center'>
              {title}
            </CardTitle>
          )}
          {plan.coaches && (
            <CardSubtitle mt='4px' textAlign='center'>
              <NameList people={plan.coaches} />
            </CardSubtitle>
          )}
        </Box>
      </Flex>
    )
  }
)

ZenCard.propTypes = {
  duration: PropTypes.string,
  instructors: PropTypes.array,
  primaryCategory: PropTypes.object,
  primarySubcategory: PropTypes.object,
  title: PropTypes.string.isRequired
}

export default ZenCard

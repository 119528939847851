import { useEffect, useRef, useState } from 'react'

// Note that if threshold is an array it MUST be defined outside of the consuming component's body.
// This is because if it is defined inline, it will re-create the array every re-render, triggering a disconnect and reconnect of the observer.
const useIntersectionObserver = ({
  root = null,
  rootMargin,
  threshold = 0
} = {}) => {
  const [entry, setEntry] = useState({})
  const [node, setNode] = useState(null)

  const observer = useRef(null)

  useEffect(() => {
    if (observer.current) observer.current.disconnect()

    observer.current = new window.IntersectionObserver(
      ([entry]) => setEntry(entry),
      {
        root,
        rootMargin,
        threshold
      }
    )

    const { current: currentObserver } = observer

    if (node) currentObserver.observe(node)

    return () => currentObserver.disconnect()
  }, [node, observer, root, rootMargin, threshold])

  return [entry, setNode]
}

export default useIntersectionObserver

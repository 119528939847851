import { useEffect } from 'react'

/**
 * We listen to mousedown events and apply a class to the document body that will remove all :focus { outline } elements in CSS.
 * Once a user presses a mousedown event, those focus events return.
 */
const useToggleFocus = () => {
  useEffect(() => {
    document.body.addEventListener('mousedown', () => {
      document.body.classList.add('using-mouse')
    })

    document.body.addEventListener('keydown', () => {
      document.body.classList.remove('using-mouse')
    })

    return () => {
      document.body.removeEventListener('mousedown', () => {
        document.body.classList.add('using-mouse')
      })

      document.body.removeEventListener('keydown', () => {
        document.body.classList.remove('using-mouse')
      })
    }
  }, [])
}

export default useToggleFocus

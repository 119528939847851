import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { LeftArrow } from 'images/latest'

const LeftArrowIcon = ({
  color = 'black',
  width = '16px',
  height = '16px'
}) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    fill={color}
    SVG={LeftArrow}
  />
)

export default LeftArrowIcon

import React from 'react'
import { useNavbarContext } from '../common/data/_store'
import { Responsive, Box } from 'ui/acro'
import { HamburgerMenuIcon } from 'components_v2/Icons'
import NotificationBadge from '../Desktop/RightContent/Profile/NotificationBadge'

const MobileMenuContainer = ({ menuIsOpen, toggleMenu }) => {
  const { state } = useNavbarContext()

  return (
    <Responsive show={[true, true, true, false]} position='relative'>
      {/* Mobile/tablet menu */}
      <Responsive
        show={[true, true, true, false]}
        position='relative'
        flexGrow={0}
      >
        <Box
          onClick={toggleMenu}
          aria-label={menuIsOpen ? 'Close Navbar' : 'Open Navbar'}
          id='menu_open_mobile'
          padding='5px'
        >
          <HamburgerMenuIcon color='black' />
        </Box>
        {state.notificationCount > 0 && (
          <NotificationBadge position='absolute' right='-5px' />
        )}
      </Responsive>
    </Responsive>
  )
}

export default MobileMenuContainer

import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { Camera } from 'images/latest'

const CameraIcon = ({ color = 'black', width = '30px', height = '30px' }) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    fill={color}
    SVG={Camera}
  />
)

export default CameraIcon

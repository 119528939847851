import axios from 'axios'
import {
  axiosInstanceConfig,
  interceptRequest,
  interceptResponseSucess,
  interceptResponseError
} from './config'

const axiosInstance = axios.create(axiosInstanceConfig)

axiosInstance.interceptors.request.use(interceptRequest)

axiosInstance.interceptors.response.use(
  interceptResponseSucess,
  interceptResponseError
)

export default axiosInstance

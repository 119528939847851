import React from 'react'

import { Caption } from 'ui/acro'

const MobileMenuLink = ({ open, ...props }) => (
  <Caption
    variant='hard'
    fontSize='16px'
    as='a'
    pt='15px'
    pb='14px'
    px='15px' // Inset
    color='black'
    className='unstyled'
    fontWeight='900'
    tabIndex={open ? '0' : '-1'}
    {...props}
  />
)

export default MobileMenuLink

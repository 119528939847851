import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { CommunityWalking } from 'images/latest'

const CommunityWalkingIcon = ({ color = 'black' }) => (
  <ResponsiveSVG
    width='45px'
    height='33px'
    className='unstyled'
    fill={color}
    SVG={CommunityWalking}
  />
)

export default CommunityWalkingIcon

import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import AnimateCollapse from 'react-collapse'

import { useSearchAPI } from 'hooks'

import { Input as unstyledInput, Box } from 'ui/acro'
import { CloseIcon, DesktopSearchIcon } from 'components_v2/Icons'
import { CSSTransition } from 'react-transition-group'
import DrawerResults from './SearchDrawer/DrawerResults'

const SearchContainer = styled('div')({
  position: 'absolute',
  width: '100%',
  right: '0',
  top: '0',
  height: '100%',
  backgroundColor: 'white',
  zIndex: '2',
  '&.desktop-search-enter': {
    opacity: '0'
  },
  '&.desktop-search-enter-active': {
    opacity: '1',
    transition: 'opacity .5s ease'
  },
  '&.desktop-search-exit': {
    opacity: '1'
  },
  '&.desktop-search-exit-active': {
    opacity: '0',
    transition: 'opacity .5s ease'
  }
})

const SearchForm = styled('form')({
  position: 'absolute',
  width: '80%',
  top: '21px',
  right: '0',
  height: '40px',
  display: 'flex',
  backgroundColor: 'white',
  justifyContent: 'space-between',
  // Because we render this in legacy templates, we need to override padding set by application.css
  padding: '0'
})

const Input = styled(unstyledInput)({
  fontSize: '16px',
  paddingLeft: '4em',
  borderRadius: '100px',
  position: 'relative',
  width: '75%',
  color: '#000000',
  border: '1px solid #E1E1E1',
  marginTop: '0px', // Set mt to 0 to override existing styles for inputs.
  '::placeholder': {
    color: '#636363'
  },
  '&:focus': {
    backgroundColor: '#F2F2F2',
    border: '1px solid #F2F2F2'
  }
})

const CollapseContainer = styled('div')((props) => ({
  '& > .ReactCollapse--collapse': {
    position: 'absolute',
    transition: 'height 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    width: '100%',
    zIndex: '4',
    top: '5em',
    '& > .ReactCollapse--content': {
      display: 'flex',
      flexDirection: 'column',
      background: props.theme.colors.base,
      minWidth: '286px',
      boxShadow: `0 8px 16px -10px #808080c7`,
      zIndex: '4',
      paddingTop: '20px',
      paddingBottom: '20px'
    }
  }
}))

const OverlayContainer = styled(Box)({
  height: '100vw',
  width: '100vw',
  backgroundColor: '#242424',
  position: 'absolute',
  top: '80px',
  left: '0',
  opacity: '.3'
})

const CancelButton = styled('button')({
  fontFamily: 'Proxima-nova, Arial, sans-serif',
  letterSpacing: '0.02em',
  minWidth: '100px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #A3A3A3',
  transition: '.5s',
  borderRadius: '124px',
  position: 'absolute',
  right: '30px',
  top: '22px',
  padding: '10px 20px',
  '@media only screen and (min-width: 1200px)': {
    '&:hover': {
      backgroundColor: '#F2F2F2',
      border: '1px solid #F2F2F2',
      transition: '.5s'
    }
  }
})

const InputIcon = styled(Box)({
  position: 'absolute',
  zIndex: '3',
  top: '9px',
  left: '25px'
})

const DesktopSearch = ({ color = 'black' }) => {
  const {
    value,
    debouncedValue,
    data,
    handleBlur,
    handleChange,
    handleSubmit
  } = useSearchAPI()

  const [showSearchBar, setShowSearchBar] = useState(false)

  const searchInput = useRef(null)

  const clearInput = () => {
    handleBlur()
    searchInput.current.focus()
  }

  const closeSearch = () => {
    handleBlur()
    setShowSearchBar(false)
  }

  return (
    <Box>
      {/* the search icon that shows in the navbar to open search */}
      <Box
        cursor='pointer'
        onClick={() => {
          setShowSearchBar(true)
        }}
      >
        <DesktopSearchIcon color={color} />
      </Box>
      {/* the transition box that wraps the full-width searchbar input over the navbar */}
      <CSSTransition
        in={showSearchBar}
        timeout={500}
        appear
        mountOnEnter
        unmountOnExit
        classNames='desktop-search'
        onEnter={() => setShowSearchBar(true)}
        onExited={() => setShowSearchBar(false)}
      >
        <SearchContainer>
          <SearchForm
            id='search-form'
            autoComplete='off'
            onSubmit={handleSubmit}
          >
            <InputIcon>
              <DesktopSearchIcon
                color={color}
                strokeWidth='0px'
                height='22px'
                width='22px'
              />
            </InputIcon>
            <Input
              id='desktop-search-form-input'
              ref={searchInput}
              autoFocus
              value={value}
              onChange={handleChange}
              placeholder='Search for Classes, Series, Instructors, or Playlists'
              aria-label='Search for Classes, Series, Instructors, or Playlists'
            />
            {value && (
              <Box
                onClick={clearInput}
                position='absolute'
                cursor='pointer'
                right='27%'
                top='35%'
              >
                <CloseIcon color='black' />
              </Box>
            )}
          </SearchForm>
          <CancelButton onClick={closeSearch}>cancel</CancelButton>

          <CollapseContainer>
            <AnimateCollapse isOpened={data}>
              <DrawerResults
                data={data}
                value={debouncedValue}
                handleSubmit={handleSubmit}
              />
            </AnimateCollapse>
          </CollapseContainer>

          <OverlayContainer onClick={closeSearch}></OverlayContainer>
        </SearchContainer>
      </CSSTransition>
    </Box>
  )
}

export default DesktopSearch

import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { ChevronDown } from 'images/latest'

const ChevronDownIcon = ({ color = 'white' }) => (
  <ResponsiveSVG
    width='14px'
    height='10px'
    className='unstyled chevron-down-icon'
    fill={color}
    SVG={ChevronDown}
  />
)

export default ChevronDownIcon

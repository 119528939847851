// Footer heights are numbers because math
const heights = {
  base: {
    // Includes internal top/bottom padding
    mobile: 157,
    desktop: 114
  },
  links: {
    mobile: 39,
    desktop: 57
  },
  pad: {
    mobile: 48,
    desktop: 100
  }
}

// This is accounts for setting a min-height on the parent content container
// so that the footer is at the bottom of the window.
// See GlobalStyle.js style: body #react-body
const footerHeight = ({ pad = false, links = true, flush = {} } = {}) => {
  let mobile = heights.base.mobile
  let desktop = heights.base.desktop

  if (links) {
    mobile += heights.links.mobile
    desktop += heights.links.desktop
  }

  // Footer is positioned absolutely on v2 React pages
  // pad is true when calculating bottom padding for main content (see TempEntry.js)
  if (pad) {
    if (!flush.mobile) mobile += heights.pad.mobile
    if (!flush.desktop) desktop += heights.pad.desktop
  }

  return [`${mobile}px`, null, `${desktop}px`]
}

export default footerHeight

import React from 'react'

import { useCookies } from 'react-cookie'
import axios from 'axios'

const useSurveyDataCookie = () => {
  const [, setCookie] = useCookies(['user_starts_survey'])

  const FetchSurveyUserData = async (cookie) => {
    try {
      const res = await axios.get('/api/v2/onboarding_recommendations/version')
      const surveyData = await res.data
      const keyToRead = 'v3_survey'
      const surveyUserValue = surveyData[keyToRead]

      !surveyUserValue && setCookie(cookie, true)
    } catch (e) {
      console.log(e)
    }
  }

  return { FetchSurveyUserData }
}

export default useSurveyDataCookie

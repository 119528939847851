/**
 * The `utmParams()` helper is used to create an object based upon utms in a location's query params.
 * 
 * @returns {object} An object in the shape of { [tag]: param } corresponding to common utms (see `const tags` in body)
 */

const utmParams = () => {
  const tags = ['source', 'medium', 'campaign', 'term', 'content']
  const params = new URLSearchParams(window.location.search)
  // compile a new object in the form of {tag: param}
  return tags.reduce((res, tag) => ({ ...res, [tag]: params.get(`utm_${tag}`)}), {})
}

export default utmParams

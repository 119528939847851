import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react'
import {
  useMotionValue,
  useSpring,
  useTransform,
  useViewportScroll
} from 'framer-motion'
import useResizeObserver from 'use-resize-observer'

import useIntersectionObserver from 'hooks/useIntersectionObserver'
import usePrefersReducedMotion from 'hooks/usePrefersReducedMotion'

const ParallaxContext = createContext(null)
export const useParallaxContext = () => useContext(ParallaxContext)

const ParallaxProvider = ({ Wrapper = 'div', children, degrees = 10 }) => {
  const reduceMotion = usePrefersReducedMotion()
  const wrapperRef = useRef()
  const columnRef = useRef()
  const { height } = useResizeObserver({ ref: wrapperRef })

  const [{ isIntersecting }, setNode] = useIntersectionObserver()
  const diff = useMotionValue(75)
  const { scrollY } = useViewportScroll()
  const [range, setRange] = useState({
    y: 0,
    x: 0
  })

  const setWrapperRef = useCallback(
    (elem) => {
      if (elem) {
        setNode(elem)
        wrapperRef.current = elem
      }
    },
    [setNode, wrapperRef]
  )

  /* 
    When wrapper is intersecting, listen to scroll and find what percent the top of the banner is from the top of viewport.
    We do this so we can synch the parallax effect with scrolling.
  */
  useEffect(() => {
    if (wrapperRef && wrapperRef.current && scrollY && isIntersecting && diff) {
      if (!reduceMotion) {
        const heightDiff = () => {
          const top = wrapperRef.current.getBoundingClientRect().top
          const viewportHeight = window.innerHeight
          const percent = (top / viewportHeight) * 100
          diff.set(percent)
        }

        const unsubscribe = scrollY.onChange((y) => heightDiff(y))
        return () => unsubscribe()
      }
    }
  }, [scrollY, diff, wrapperRef, isIntersecting, reduceMotion])

  /* 
    These are our trig functions to make sure that the columns x and y motions are relative to their heights.

    Y tells us how much room is below (above for reverse) the wrapper. 
    This is the amount we want to move the column relative to scrolling.

    X is the amount that we need to move the column to compensate for its tilt.
    
    We have a right triangle formed by the tilt (theta) and the offset of Y.
    tan(deg) = x / y --> x = tan(deg) * y
  */
  useEffect(() => {
    if (
      height &&
      wrapperRef.current &&
      columnRef.current
    ) {
      /* 
        This gives us the distance needed to travel to get to the end of each column.
        Note that the top half (or bottom for reversed) will just about NEVER be displayed.
      */
      const colHeight = columnRef.current.getBoundingClientRect().height
      const wrapperHeight = wrapperRef.current.getBoundingClientRect().height
      const y = (colHeight - wrapperHeight) / 2

      // convert degrees to radians. See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/tan
      const x = Math.tan((degrees * Math.PI) / 180) * y

      setRange({
        y,
        x
      })
    }
  }, [degrees, height, wrapperRef, columnRef, setRange])

  /*
    We create two transforms for each axis and for each direction.
    Child components use these to determine how they should move.
  */

  const yTransform = useTransform(diff, [100, -100], [0, -range.y])
  const y = useSpring(yTransform, { stiffness: 30, mass: 0.3, damping: 7.5 })
  const xTransform = useTransform(diff, [100, -100], [0, range.x])
  const x = useSpring(xTransform, { stiffness: 30, mass: 0.3, damping: 7.5 })

  const yReverseTransform = useTransform(diff, [100, -100], [0, range.y])
  const yReverse = useSpring(yReverseTransform, {
    stiffness: 30,
    mass: 0.3,
    damping: 7.5
  })
  const xReverseTransform = useTransform(diff, [100, -100], [0, -range.x])
  const xReverse = useSpring(xReverseTransform, {
    stiffness: 30,
    mass: 0.3,
    damping: 7.5
  })

  return (
    <ParallaxContext.Provider
      value={{
        y,
        x,
        yReverse,
        xReverse,
        degrees
      }}
    >
      <Wrapper ref={setWrapperRef}>
        {React.Children.map(children, (elem, index) => {
          if (index === 0) return React.cloneElement(elem, { ref: columnRef })
          return elem
        })}
      </Wrapper>
    </ParallaxContext.Provider>
  )
}

export default ParallaxProvider

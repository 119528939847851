import styled from 'styled-components'
import { css } from '@styled-system/css'
import Text from 'ui/acro/typography/Text'

const HeaderText = styled(Text)(() =>
  css({
    fontWeight: 900,
    fontSize: ['1.5rem', '1.5rem', '2.5rem'],
    textTransform: 'uppercase',
    fontFamily: 'Arquitecta',
    lineHeight: '26px',
    letterSpacing: '0.07em'
  })
)
export default HeaderText

import React from 'react'
import { TrashLight } from 'images/latest'
import { ResponsiveSVG } from 'ui/acro'

const TrashLightIcon = ({
  color = '#A3A3A3',
  width = '24px',
  height = '24px'
}) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled trash-light'
    color={color}
    SVG={TrashLight}
  />
)

export default TrashLightIcon

import React from 'react'
import styled from 'styled-components'

import { Fade, Flex } from 'ui/acro'

const MenuContainer = styled(Flex)((props) => ({
  background: `${props.theme.colors.base}`,
  minWidth: '150px',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: `0 8px 16px -10px #808080c7`,
  zIndex: 10,
  padding: '20px 30px'
}))

const DropdownMenu = ({ isHovered, children, width, ...rest }) => (
  <Fade inTrigger={isHovered} {...rest} position='absolute'>
    <MenuContainer className='menu-container' width={width}>
      {children}
    </MenuContainer>
  </Fade>
)

export default DropdownMenu

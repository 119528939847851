import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { CommunityYoga } from 'images/latest'

const CommunityYogaIcon = ({ color = 'black' }) => (
  <ResponsiveSVG
    width='46px'
    height='33px'
    className='unstyled'
    fill={color}
    SVG={CommunityYoga}
  />
)

export default CommunityYogaIcon

import { axios } from 'api'
import uuidv4 from 'uuid/v4'

const fetchHandleCarouselVideoClicks = async (params, url) => {
  try {
    await axios.post('video_events/handle_carousel_video_clicks', params)
  } catch (error) {
    // Fail silently
    console.log(error)
  } finally {
    window.location.assign(url)
  }
}

const handleVideoClick = (id, videoOriginType, position, url) => {
  const client_uuid = uuidv4()
  const identifier = id
  const location = window.location.pathname === '/' ? 'homepage' : 'welcome_page'
  const postBody = {
    id: identifier,
    video_origin_type: videoOriginType,
    location: location,
    position: position,
    client_uuid
  }

  fetchHandleCarouselVideoClicks(postBody, url)
}

export default handleVideoClick

import { useState, useEffect } from 'react'

// this represents all of the focusable elements we wish to include.
// Might be a good refactor to enable users to append this with their own elements as well.
const FOCUSABLE_STRING =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]'

/**
 * This is a *DANGEROUS* hook, and is thus labelled.
 * It takes the id of a DOM node and supplies two helper functions; a setter and a cleanup.
 * The setter will iterate through the DOM node and its children searching for focusable elements.
 * It will then TRAP THE USER KEYBOARD within those elements until the cleanup function clearKeyboardTrap_Dangerous() is called.
 *
 * ONLY USE THIS HOOK TO IMPROVE ACCESSIBILITY! BE VERY CAREFUL WHEN IMPLEMENTING IT!
 * @param {string} id The id of the element we want to set the Keyboard trap on.
 *
 * @returns {Object} hookReturn The setter and cleanup functions
 * @returns {function} hookReturn.setKeyboardTrap_Dangerous - sets the Keyboard trap. Remember to call clearKeyboardTrap_Dangerous() when finished!
 * @returns {function} hookReturn.clearKeyboardTrap_Dangerous - clears the Keyboard trap.
 */
const useKeyboardTrap_Dangerous = (id) => {
  const [tabs, setTabs] = useState(null)

  const trapTabKey = (e) => {
    // Check for TAB key press
    if (e.keyCode === 9) {
      // SHIFT + TAB
      if (e.shiftKey) {
        if (document.activeElement === tabs.first) {
          e.preventDefault()
          tabs.last.focus()
        }

        // TAB
      } else {
        if (document.activeElement === tabs.last) {
          e.preventDefault()
          tabs.first.focus()
        }
      }
    }
  }

  /**
   * Cleans up and removes the Keyboard trap.
   */
  const clearKeyboardTrap_Dangerous = () => {
    setTabs(null)
  }

  /**
   * Sets up the Keyboard trap. Remember to call clearKeyboardTrap_Dangerous() when finished.
   */
  const setKeyboardTrap_Dangerous = () => {
    const element = document.getElementById(id)
    if (element) {
      let focusableElements = element.querySelectorAll(FOCUSABLE_STRING)
      // convert NodeList to Array
      focusableElements = Array.prototype.slice.call(focusableElements)
      if (focusableElements.length > 0) {
        const boundaries = {
          first: focusableElements[0],
          last: focusableElements[focusableElements.length - 1]
        }

        // Set first item as focused and update state.
        boundaries.first.focus()
        setTabs(boundaries)
      }
    }
  }

  /* Add the keyboard trap to 'keydown' event and cleanup as needed */
  useEffect(() => {
    if (tabs) {
      document.addEventListener('keydown', trapTabKey)
    }

    return () => {
      if (tabs) {
        document.removeEventListener('keydown', trapTabKey)
      }
    }
  }, [tabs])

  return { setKeyboardTrap_Dangerous, clearKeyboardTrap_Dangerous }
}

export default useKeyboardTrap_Dangerous

import React from 'react'
import styled from 'styled-components'

import { useUserContext } from 'context'

const RoundIcon = styled.div`
  width: 1.75rem;
  height: 1.75rem;
  background-color: ${(props) => (props.isHovered ? '#000' : '#e0d7d1')};
  color: ${(props) => (props.isHovered ? '#FFF' : '#000')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arquitecta;
  font-size: 0.625rem;
  font-weight: 900;
  letter-spacing: 0.04375rem;
  text-transform: uppercase;
`

const ProfileIcon = ({ isHovered }) => {
  const { user } = useUserContext()
  const firstNameChar = user?.firstName?.charAt(0) || ''
  const lastNameChar = user?.lastName?.charAt(0) || ''
  const profileText = firstNameChar + lastNameChar

  return <RoundIcon isHovered={isHovered}>{profileText}</RoundIcon>
}

export default ProfileIcon

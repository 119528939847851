import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Flex } from 'ui/acro'

// We don't want to have this menu affected by 'DarkMode', since it would turn it white and completely defeat the point.
// Source: https://codepen.io/naturalclar/pen/zEwvbg?editors=0010
const StyledMobileMenu = styled(Flex)((props) =>
  css({
    background: `${props.theme.colors.white}`,
    flexDirection: 'column',
    px: [null, null, '46px'], // Account for inset: 19-4, 50-4
    display: ['flex', null, null, 'none'],
    color: `${props.theme.colors.black}`,
    position: 'fixed',
    top: '0',
    left: '0',
    overflowY: 'auto',
    width: '100%',
    zIndex: '10001' /*  Existing CSS defines many z-indexes as 10000+ */
  })
)

export default StyledMobileMenu

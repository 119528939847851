import { useState, useEffect } from 'react'
import { useField } from 'formik'
import useDebounce from './useDebounce'

const useComboboxSearchWithFormik = (fieldName, getItems) => {
  const [{ value: items }, , { setValue: setItems }] = useField(
    `${fieldName}Items`
  )
  const [loading, setLoading] = useState(false)
  const [{ value, ...field }, { touched, error }, { setValue }] = useField(
    fieldName
  )
  const [inputValue, setInputValue] = useState(value)
  const [requery, setRequery] = useState(true)
  const debouncedInput = useDebounce(inputValue, 250)

  const handleSetInputValue = (value, requery) => {
    setInputValue(value)
    setRequery(requery)
  }

  useEffect(() => {
    setLoading(inputValue.length > 0)
  }, [inputValue])

  useEffect(() => {
    const asyncGetItems = async () => {
      const newItems = await getItems(debouncedInput)
      if (newItems) {
        setItems(newItems)
      }
    }

    try {
      if (
        debouncedInput.length > 0 &&
        getItems &&
        typeof getItems === 'function' &&
        requery
      ) {
        /* Because getItems is most likely async (i.e. api call), we need to wrap it in async/await here to call it properly. */
        asyncGetItems()
      }
    } catch (e) {
      // API call threw an error, do something here?
      throw e
    } finally {
      setLoading(false)
    }
  }, [debouncedInput, requery])

  return {
    ...field,
    value,
    setValue,
    inputValue,
    setInputValue: handleSetInputValue,
    showError: touched && Boolean(error),
    name: fieldName,
    error,
    items,
    loading
  }
}

export default useComboboxSearchWithFormik

import styled from 'styled-components'
import { variant } from 'styled-system'
import { P } from 'ui/acro'

const CardTitle = styled(P)(
  {
    fontFamily: 'Proxima-Nova',
    fontSize: '1em',
    fontWeight: '700',
    letterSpacing: 'normal',
    lineHeight: '19.2px',
    color: 'black'
  },
  variant({
    variants: {
      primary: {
        letterSpacing: '2.5px',
        fontSize: ['16px', '16px', '24px', '24px'],
        textAlign: 'center',
        lineHeight: '28px',
        fontFamily: 'Arquitecta',
        fontWeight: '600',
        textTransform: 'uppercase'
      }
    }
  })
)

export default CardTitle

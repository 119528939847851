import { useQuery } from 'react-query'

import axios from './axios.config'

const getActiveBillboards = async () => {
  const { data } = await axios.get('/billboard/active')
  return data
}

export const useGetActiveBillboards = () => {
  return useQuery('activeBillboards', getActiveBillboards)
}

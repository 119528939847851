import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { ContentSeries } from 'images/latest'

const ContentSeriesIcon = ({ color = 'black' }) => (
  <ResponsiveSVG
    width='16px'
    height='16px'
    className='unstyled'
    fill={color}
    SVG={ContentSeries}
  />
)

export default ContentSeriesIcon

import { useEffect } from 'react'

const useBeforeUnload = (confirm = true) => {
  useEffect(() => {
    // Show browser-defined message to confirm leaving page
    const callback = (e) => {
      if (confirm) {
        e.preventDefault()
        e.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', callback)

    return () => {
      window.removeEventListener('beforeunload', callback)
    }
  }, [confirm])
}

export default useBeforeUnload

/**
 * Extract the file extension from a given file name.
 * This does not validate the file type in any way, it simply returns the extension from a string.
 * @param {string} fileName - The name of the file you want to extract the extension from.
 * 
 * @return {string} The extension of an associated file name.
 */
const getFileExtension = (fileName) => {
  return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase()
}

export default getFileExtension
import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { Info } from 'images/latest'

const InfoIcon = ({ color = 'black', width = '16px', height = '16px' }) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    color={color}
    SVG={Info}
  />
)

export default InfoIcon

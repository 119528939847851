import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { FAQBackArrow } from 'images/latest'

const FAQBackArrowIcon = ({ color = 'black' }) => (
  <ResponsiveSVG
    width='30px'
    height='18px'
    className='unstyled'
    fill={color}
    SVG={FAQBackArrow}
  />
)

export default FAQBackArrowIcon

import React from 'react'
import { FlagLight } from 'images/latest'
import { ResponsiveSVG } from 'ui/acro'

const FlagLightIcon = ({
  color = '#A3A3A3',
  width = '24px',
  height = '24px'
}) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled flag-light'
    color={color}
    SVG={FlagLight}
  />
)

export default FlagLightIcon

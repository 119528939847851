import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { Close } from 'images/latest'
const CloseIcon = ({ width = '12px', height = '12px', color, id }) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    color={color}
    SVG={Close}
    id={id}
  />
)
export default CloseIcon

import styled from 'styled-components'
import { css } from '@styled-system/css'
import Text from 'ui/acro/typography/Text'

const AltHeaderText = styled(Text)(() =>
  css({
    fontWeight: 400,
    fontSize: ['1.5rem', '1.5rem', '2rem'],
    whiteSpace: 'noWrap',
    fontFamily: 'Arquitecta',
    lineHeight: ['28.35px', '37.7px'],
    textTransform: 'uppercase',
    letterSpacing: '0.14rem'
  })
)
export default AltHeaderText

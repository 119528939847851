import { useState, useEffect, useReducer } from 'react'
import { axios } from 'api'

import dataFetchReducer from './reducer'
import { CLEAR_DATA, FETCH_START, FETCH_SUCCESS, FETCH_FAIL } from './actions'

/***********************
/*  useFetchAPI() is used to retreive data from an API on page load.
/*  It returns the following object:
/*    {
/*      data: :null || res.data: -  the data returned from the API extracted from from res.data
/*      loading: :bool: - whether the API data is still loading
/*      error: :bool: - whether the hook received an error from the API call
/*    }
/************************
/*  To use this hook:
/*    const [{ data, loading, error }] = useFetchAPI(apiURL)
/***********************/

// Actions are defined in hook because we would otherwise need to pass dispatch function into them, and it becomes a needlessly complex system.

const useFetchAPI = (initialUrl, options = {}, axiosOptions) => {
  const { initialData, cacheKeys } = options
  const [url, setUrl] = useState(() => {
    let constructedUrl = initialUrl

    if (cacheKeys && cacheKeys.length > 0) {
      // set cache query params based on passed in cacheKeys.
      // We are simply converting the cacheKeys into a single string and appending it. We may want to hash this key in the future to save space.
      constructedUrl += `${
        constructedUrl.includes('?') ? '&' : '?'
      }cacheKey=${cacheKeys.join('')}`
    }

    return constructedUrl
  })

  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: true,
    error: false,
    data: initialData
  })

  useEffect(() => {
    // didCancel is changed to true on hook cleanup. This prevents us from writing data to a stale store.
    let didCancel = false

    if (url) {
      const fetchData = async () => {
        dispatch({ type: FETCH_START })

        try {
          const res = await axios(url, axiosOptions)

          if (!didCancel) {
            dispatch({
              type: FETCH_SUCCESS,
              payload: res.status === 204 ? null : res.data
            })
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({
              type: FETCH_FAIL
            })
          }
        }
      }
      fetchData()
    } else {
      dispatch({
        type: CLEAR_DATA
      })
    }

    return () => {
      // On hook cleanup, set didCancel to true
      didCancel = true
    }
  }, [url])

  return [state, setUrl]
}

export default useFetchAPI

import { useEffect } from 'react'
import lodashDebounce from 'lodash.debounce'

//
// This hook will call scrollEndCallback when scrolling reaches the bottom and stops
// and will call scrollDefaultCallback (if defined) when scrolling stops anywhere else
//
const useScrollEnd = (scrollEndCallback, scrollDefaultCallback) => {
  useEffect(() => {
    const handleOnScroll = (e) => {
      const node = document.documentElement
      const scrollPos = Math.round(node.scrollHeight)
      const bottomPos = Math.round(node.scrollTop + window.innerHeight)

      if (scrollPos == bottomPos) {
        typeof scrollEndCallback === 'function' && scrollEndCallback()
      } else {
        typeof scrollDefaultCallback === 'function' && scrollDefaultCallback()
      }
    }

    const callback = lodashDebounce(handleOnScroll, 100, { trailing: true })
    window.addEventListener('scroll', callback)

    return () => {
      window.removeEventListener('scroll', callback)
    }
  }, [scrollEndCallback, scrollDefaultCallback])
}

export default useScrollEnd

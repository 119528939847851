import {
  FINISH_UPLOAD_FAIL,
  FINISH_UPLOAD_SUCCESS,
  INIT_UPLOAD,
  UPDATE_ARRAYS,
  UPDATE_PROGRESS,
  UPLOAD_RETRY,
  UPDATE_CHUNK_DATA
} from './actions'

const INITIAL_UPLOAD_STATE = {
  error: false,
  uploadStarted: false,
  uploadFinished: false,
  retry: false,
  percentComplete: 0
}

const reducer = (state, actions) => {
  switch (actions.type) {
    case FINISH_UPLOAD_FAIL: {
      const { id, error } = actions.payload

      return {
        ...state,
        [id]: {
          ...state[id],
          uploadFinished: true,
          error: error,
          retry: false
        }
      }
    }

    case FINISH_UPLOAD_SUCCESS: {
      const { id } = actions.payload

      return {
        ...state,
        [id]: {
          ...state[id],
          percentCompleted: 100, // We force 100% complete here, as a resumed upload with no remainingParts will already be at 100%.
          uploadFinished: true,
          retry: false
        }
      }
    }

    case INIT_UPLOAD: {
      const { id } = actions.payload

      return {
        ...state,
        [id]: {
          ...INITIAL_UPLOAD_STATE
        }
      }
    }

    case UPDATE_CHUNK_DATA: {
      const {
        id,
        uploadedParts,
        remainingParts,
        resumed,
        uuid
      } = actions.payload

      // Copy uploaded and remaining arrays into our state so we can modify them as we iterate.
      const uploadedPartsCopy = [...uploadedParts]
      const remainingPartsCopy = [...remainingParts]

      // Get remaining percent for any uploads (resumed or not)
      const length = remainingParts.length + uploadedParts.length
      const percentComplete = resumed ? remainingParts / length : 0

      return {
        ...state,
        [id]: {
          ...state[id],
          uploadStarted: true,
          uuid,
          resumed,
          remainingParts: remainingPartsCopy,
          uploadedParts: uploadedPartsCopy,
          length,
          percentComplete
        }
      }
    }

    case UPDATE_ARRAYS: {
      const { id, part } = actions.payload

      const prevRemainingParts = state[id].remainingParts
      const prevUploadedParts = state[id].uploadedParts
      const remainingParts = prevRemainingParts.filter(
        (existingPart) => existingPart !== part
      )
      const uploadedParts = prevUploadedParts.concat(part)

      return {
        ...state,
        [id]: {
          ...state[id],
          remainingParts,
          uploadedParts
        }
      }
    }

    case UPDATE_PROGRESS: {
      // Update the percent completed.
      const { id, loaded, total } = actions.payload

      let percentComplete = state[id].percentComplete
      // To determine percentComplete, we need to find out how far we are within a given chunk (loaded / total), add that to the total amount we have already completed, then divide by our total length.
      const percent = Math.floor(
        ((state[id].uploadedParts.length + loaded / total) / state[id].length) *
          100
      )

      if (percent !== percentComplete) {
        percentComplete = percent
      }

      return {
        ...state,
        [id]: {
          ...state[id],
          percentComplete
        }
      }
    }

    case UPLOAD_RETRY: {
      const { error, id } = actions.payload

      return {
        ...state,
        [id]: {
          ...state[id],
          retry: true,
          error
        }
      }
    }
  }
}

export default reducer

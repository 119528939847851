import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import AnimateCollapse from 'react-collapse'
import { CloseIcon, MobileSearchIcon, LeftArrowIcon } from 'components_v2/Icons'

import { useBodyScrollLock, useOutsideClick, useSearchAPI } from 'hooks'
import { Box, Flex, Input as UnstyledInput } from 'ui/acro'
import { defaultValues as inputStyles } from 'ui/acro/typography/P'
import { CSSTransition } from 'react-transition-group'
import DrawerResults from './SearchDrawer/DrawerResults'

const CSSTransitionBox = styled(CSSTransition)(() =>
  css({
    '&.mobile-search-enter-active': {
      height: '0',
      opacity: '0'
    },
    '&.mobile-search-enter-done': {
      height: '100%',
      transition: '.3s',
      opacity: '1'
    },
    '&.mobile-search-exit': {
      height: '100%',
      opacity: '1'
    },
    '&.mobile-search-exit-active': {
      height: '0',
      transition: '.3s',
      opacity: '0'
    }
  })
)

const SearchContainer = styled(Flex)((props) =>
  css({
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    width: '100%',
    background: props.theme.colors.base,
    alignItems: 'center',
    boxShadow:
      '0px 1px 4px rgba(0, 0, 0, 0.06), 0px 8px 16px rgba(35, 41, 54, 0.08)'
  })
)

const SearchForm = styled('form')(
  css({
    display: 'flex',
    alignItems: 'center',
    padding: ['0 15px', '0 20px'], // Because we render this in legacy templates, we need to override padding set by application.css
    width: '100%'
  })
)

const SearchInput = styled(UnstyledInput)(
  css({
    ...inputStyles,
    marginTop: '0px', // Set mt to 0 to override existing styles for inputs.
    border: 'none',
    outline: 'none',
    color: '#000000',
    letterSpacing: '.02em',
    padding: 0,
    width: '100%',
    '&:focus': {
      '::placeholder': {
        color: '#cccccc'
      }
    }
  })
)

const CollapseContainer = styled('div')((props) =>
  css({
    '& > .ReactCollapse--collapse': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '100%',
      transition: 'height 250ms cubic-bezier(0.4, 0, 0.2, 1)',

      '& > .ReactCollapse--content': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxHeight: '100vh',
        bg: props.theme.colors.base,
        boxShadow: `0 8px 16px -10px #808080c7`
      }
    }
  })
)

const IconsContainer = styled(Flex)(
  css({
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90px',
    '#search-enter-button-mobile': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'black',
      borderRadius: '4px',
      padding: '10px',
      '#mobile-search-icon': {
        margin: 'auto',
        display: 'block'
      }
    }
  })
)

const MobileSearch = () => {
  const {
    value,
    debouncedValue,
    data,
    handleBlur,
    handleChange,
    handleSubmit
  } = useSearchAPI()

  const searchInput = useRef(null)
  const [showSearchBar, setShowSearchBar] = useState(false)

  const toggleBodyLock = useBodyScrollLock()

  const openSearch = () => {
    toggleBodyLock()
    setShowSearchBar(true)
  }
  const closeSearch = () => {
    toggleBodyLock()
    handleBlur()
    setShowSearchBar(false)
  }
  const toggleSearch = () => {
    if (showSearchBar === false) {
      openSearch()
    } else {
      closeSearch()
    }
  }
  const clearInput = () => {
    handleBlur()
    searchInput.current.focus()
  }

  const outsideClickRef = useRef()
  useOutsideClick(outsideClickRef, toggleSearch, showSearchBar)

  return (
    // below ref targets the first child element
    <Box ref={outsideClickRef}>
      <>
        <Box onClick={openSearch}>
          <MobileSearchIcon fill='black' />
        </Box>
        <CSSTransitionBox
          in={showSearchBar}
          timeout={300}
          mountOnEnter
          unmountOnExit
          classNames='mobile-search'
          onEnter={() => {
            setShowSearchBar(true)
          }}
          onExited={() => {
            setShowSearchBar(false)
          }}
        >
          <SearchContainer className='search-container'>
            <SearchForm
              id='search-form'
              autoComplete='off'
              onSubmit={handleSubmit}
            >
              <Flex
                as='a'
                className='unstyled search-left-arrow'
                onClick={closeSearch}
                pr='15px'
              >
                <LeftArrowIcon color='black' />
              </Flex>
              <SearchInput
                id='mobile-search-form-input'
                ref={searchInput}
                autoFocus
                value={value}
                onChange={handleChange}
                placeholder='Search'
              />

              {value ? (
                <IconsContainer>
                  <Box onClick={clearInput}>
                    <CloseIcon color='black' id='search-clear-mobile' />
                  </Box>
                  <Box id='search-enter-button-mobile' onClick={handleSubmit}>
                    <MobileSearchIcon color='white' id={'mobile-search-icon'} />
                  </Box>
                </IconsContainer>
              ) : (
                <Box className='search-icon-mobile'>
                  <MobileSearchIcon color='black' />
                </Box>
              )}
            </SearchForm>
          </SearchContainer>
        </CSSTransitionBox>

        <CollapseContainer>
          <AnimateCollapse isOpened={data}>
            <DrawerResults
              data={data}
              value={debouncedValue}
              handleSubmit={handleSubmit}
            />
          </AnimateCollapse>
        </CollapseContainer>
      </>
    </Box>
  )
}

MobileSearch.propTypes = {
  open: PropTypes.bool,
  onToggle: PropTypes.func
}

export default MobileSearch

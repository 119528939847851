import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { Fire } from 'images/latest'

const FireIcon = () => (
  <ResponsiveSVG
    width={['56px', null, '56px']}
    className='unstyled'
    svgProps={{ viewBox: '0 0 56 56' }}
    SVG={Fire}
  />
)

export default FireIcon

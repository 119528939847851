import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { ChevronRight, ChevronRightBold } from 'images/latest'

const ChevronRightIcon = ({ color = 'white', isBold = false }) => (
  <>
    {isBold ? (
      <ResponsiveSVG
        // width={['15px', '15px', '15px']}
        // height='18px'
       width={['20px', '20px', '20px']}
        height='20px'
        className='unstyled chevron-right-icon'
        fill={color}
        SVG={ChevronRightBold}
      />
    ) : (
      <ResponsiveSVG
        width={['7px', '7px', '7px']}
        height='13px'
        className='unstyled chevron-right-icon'
        fill={color}
        SVG={ChevronRight}
      />
    )}
  </>
)

export default ChevronRightIcon

export const INIT_STATE = 'INIT_STATE'
export const SET_PLANS = 'SET_PLANS'
export const SET_PROMO_CODE = 'SET_PROMO_CODE'
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN'
export const SET_SUCCESSFUL_SUBMIT = 'SET_SUCCESSFUL_SUBMIT'

export const initState = (initialState) => ({
  type: INIT_STATE,
  payload: initialState
})

export const setPlans = (plans) => ({
  type: SET_PLANS,
  payload: plans
})

export const setPromoCode = ({ promoCode, plans }) => ({
  type: SET_PROMO_CODE,
  payload: {
    promoCode,
    plans
  }
})

export const setSelectedPlan = (plan) => ({
  type: SET_SELECTED_PLAN,
  payload: plan
})

export const setSuccessfulSubmit = (bool) => ({
  type: SET_SUCCESSFUL_SUBMIT,
  payload: bool
})

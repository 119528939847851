import membership from './membership'
import redemption from './redemption'
import user from './user'
import customFieldsInitializer from './customFields'

const initializers = {
  membership,
  redemption,
  user
}

export { customFieldsInitializer }

export default initializers

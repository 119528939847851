import React from 'react'
import { MobileSearch } from 'images/latest'
import { ResponsiveSVG } from 'ui/acro'

const MobileSearchIcon = ({ color, id }) => (
  <ResponsiveSVG
    width={['18px', '18px', '18px', null]}
    height={['18px', '18px', '18px', null]}
    className='unstyled'
    color={color}
    SVG={MobileSearch}
    id={id}
  />
)

export default MobileSearchIcon

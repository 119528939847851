import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Flex } from 'ui/acro'
import { Box, P, SVGImage } from 'ui/bend'
import { TwinkleIcon } from 'components_v2/Icons'
import CloseIcon from 'ui/acro/blocks/ContentModal/CloseIcon'
import { CSSTransition } from 'react-transition-group'
import { useWindowResize } from 'hooks'
import { useCookies } from 'react-cookie'
import { MASVG, BellIcon } from 'images/latest'

const getNotificationIcon = (id = '') => {
  switch (id) {
    case 'Notify':
      return <SVGImage SVG={BellIcon} backgroundColor={'black'} />
    case 'MWEPrompt':
      return <SVGImage SVG={MASVG} backgroundColor={'black'} />
    default:
      return <TwinkleIcon color='black' />
  }
}

const CSSTransitionBox = styled(CSSTransition)`
  ${css({
    '&.notification-exit': {
      transition: 'transform 0.5s'
    },
    '&.notification-exit-active': {
      transform: ['translateY(100%)', 'translateX(-125%)'],
      transition: 'transform 0.5s'
    }
  })}
`
const NotificationBox = styled('div')`
  position: fixed;
  z-index: 3;
  background-color: white;
  border-left: 13px solid #ceb18f;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06),
    0px 16px 64px rgba(35, 41, 54, 0.2);
  color: black;
  .twinkle-icon {
    flex: 0 0 20px;
  }
  #close-button {
    aspect-ratio: 1/1;
  }
  ${css({
    width: ['100%', '375px'],
    bottom: ['0', '30px'],
    left: ['0', '30px'],
    pb: ['env(safe-area-inset-bottom)', null, null, null]
  })}
`
const NotificationLink = styled(P)`
  font-family: 'Arquitecta', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  border-bottom: 1px solid black;
`

const Notification = ({ id, mainText, ctaText, ctaLink, onLearnMoreClick }) => {
  const [isClosed, setIsClosed] = useState(false)
  const [, setDismissedNotif] = useCookies(['surveyV3PromptShowed'])

  // for repositioning chat button when window is resized
  const [size, setSize] = useState()
  const { width } = useWindowResize()
  useEffect(() => {
    setSize(width < 768 ? 'mobile' : 'desktop')
  }, [width])
  const isMobile = size === 'mobile'
  const chatButton = document.querySelector('.helpButton')
  // move chat button based on notification box
  const moveChatButtonUp = () => {
    if (chatButton) {
      if (!isClosed && isMobile) {
        chatButton.style.cssText +=
          'margin-bottom:104px;transition:ease-in-out 0.5s;'
      } else if (!isMobile) {
        chatButton.style.cssText = 'margin-bottom:0px;'
      }
    }
  }
  moveChatButtonUp()

  const moveChatButtonDown = () => {
    if (chatButton) {
      if (isMobile) {
        chatButton.style.cssText =
          'margin-bottom:0px;transition:ease-in-out 0.5s;'
      } else {
        chatButton.style.cssText = 'margin-bottom:0px;'
      }
    }
  }

  const closeNotification = () => {
    moveChatButtonDown()
    setIsClosed(true)
    setDismissedNotif('dismissedNotif', true, { path: '/' }) // set this to true so the prompt isn't shown again until next login
  }
  return (
    <CSSTransitionBox
      in={!isClosed}
      timeout={500}
      unmountOnExit
      classNames='notification'
      onExited={() => setIsClosed(true)}
    >
      <NotificationBox id={id}>
        <Flex justifyContent='space-between' padding='16px 16px 16px 12px;'>
          {getNotificationIcon(id)}
          <Box padding='0 12px'>
            <P mb='8px'>{mainText}</P>
            <NotificationLink
              as='a'
              onClick={onLearnMoreClick}
              className='ctaLink'
              href={ctaLink}
            >
              {ctaText}
            </NotificationLink>
          </Box>
          <Box
            id='close-button'
            onClick={() => {
              closeNotification()
              onLearnMoreClick()
            }}
            background='#3b3b3b'
            borderRadius='50%'
            display='block'
            padding='5px 6.5px'
            height='24px'
            textdecoration='none'
          >
            <CloseIcon closeColor='white' />
          </Box>
        </Flex>
      </NotificationBox>
    </CSSTransitionBox>
  )
}

Notification.propTypes = {
  id: PropTypes.string,
  mainText: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired
}

export default Notification

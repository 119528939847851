import { useModalContext } from 'context'

/**
 * A passthrough hook for useModalContext().
 * I created this hook to reduce the cognitive load when using modals (i.e. import { useModal } from 'context' would get confusing )
 * useModal() hook can ONLY be used within a Component that is a child beneath <ModalProvider />
 *
 * See context/ModalProvider/ModalProvider.js for full documentation of supplied functions.
 * */
const useModal = () => {
  return useModalContext()
}

export default useModal

import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { CommunityLog } from 'images/latest'

const CommunityLogIcon = ({
  color = 'black',
  width = '26px',
  height = '26px'
}) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    fill={color}
    SVG={CommunityLog}
  />
)

export default CommunityLogIcon

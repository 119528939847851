import { useEffect } from 'react'
import { isSafari } from 'helpers'

// This hook will check to see if the userAgent is Safari and if page is loaded from cache. If so, it will reload the page.
// We need to do this because Safari doesn't reset our state when using the back button (Continue button still shows spinner)
const useSafariReset = () => {
  useEffect(() => {
    const resetProcessing = (e) => {
      if (e.persisted && isSafari()) {
        window.location.reload(true)
      }
    }

    window.addEventListener('pageshow', resetProcessing, false)
  }, [])
}

export default useSafariReset

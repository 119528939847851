import styled from 'styled-components'
import { css } from '@styled-system/css'
import { P } from 'ui/acro'

const Title = styled(P)`
  ${css({
    fontFamily: 'Arquitecta',
    fontWeight: '400',
    fontSize: '15px',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    color: 'black',
    margin: '0' // overwrite legacy p margin in application.css
  })}
`
export default Title

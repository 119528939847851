import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { Phone } from 'images/latest'

const PhoneIcon = () => (
  <ResponsiveSVG
    width={['56px', null, '56px']}
    className='unstyled'
    svgProps={{ viewBox: '0 0 56 56' }}
    SVG={Phone}
  />
)

export default PhoneIcon

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import css from '@styled-system/css'
import { CSSTransition } from 'react-transition-group'
import { Flex, Close, componentHeights as heights, Box } from 'ui/acro'
import Logo from '../../Logo'
import StyledMobileMenu from './common/StyledMobileMenu'
import { LeftArrowIcon } from 'components_v2/Icons'
import MobileMenuPrimary from './MobileMenuPages/MobileMenuPrimary'
import { topCategory } from '../common/data/topCategory'
import MyPractice from 'components_v2/Header/Navbar/Menus/common/MyPractice'
import TopCategory from '../common/TopCategory'

const MobileMenuHeightTransition = styled(CSSTransition)(() =>
  css({
    '&.mobile-menu-enter-active': {
      height: '0'
    },
    '&.mobile-menu-enter-done': {
      height: '100%',
      transition: '.3s'
    },
    '&.mobile-menu-exit': {
      height: '100%'
    },
    '&.mobile-menu-exit-active': {
      height: '0',
      transition: '.3s'
    }
  })
)
const FlexTopBar = styled(Flex)`
  ${css({
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: '0',
    background: '#fff',
    zIndex: '10002',
    mb: '16px',
    px: '15px'
  })}
`

const MobileMenu = ({ open, onToggle }) => {
  const topCatagories = topCategory.reduce((categories, item) => {
    return [...categories, item.key]
  }, [])

  const headerHeight = [heights.navbar.mobile, null, heights.navbar.desktop]
  const [page, setPage] = useState('Home')

  const closeMobileMenu = () => {
    onToggle()
    setPage('Home')
  }

  const pageSelected = (value) => {
    setPage(value)
  }

  const page2 = page === 'my_practice' || topCatagories.includes(page)

  return (
    <MobileMenuHeightTransition
      in={open}
      timeout={300}
      mountOnEnter
      unmountOnExit
      classNames='mobile-menu'
    >
      <StyledMobileMenu aria-hidden={open ? 'false' : 'true'}>
        <FlexTopBar height={headerHeight} minHeight={headerHeight}>
          {/* show back arrow on page 2, and on page 1 keep the flex items the same with empty div */}
          {page2 ? (
            <Flex onClick={() => setPage('Home')} margin='5px'>
              <LeftArrowIcon color='black' width='25px' />
            </Flex>
          ) : (
            <Box width='35px' />
          )}
          <Flex alignItems='center' justifyContent='center' flexGrow={1}>
            <Logo color='black' />
          </Flex>
          <Close
            onClick={closeMobileMenu}
            id='menu_close_mobile'
            fill='black'
          />
        </FlexTopBar>
        {(() => {
          if (topCatagories.includes(page)) {
            return (
              <Box padding={'20px 30px'}>
                <TopCategory hoverCategory={page} />
              </Box>
            )
          } else if (page === 'my_practice') {
            return (
              <Box padding={'20px 30px'}>
                <MyPractice />
              </Box>
            )
          } else {
            return <MobileMenuPrimary pageSelected={pageSelected} />
          }
        })()}
      </StyledMobileMenu>
    </MobileMenuHeightTransition>
  )
}

MobileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
}

export default MobileMenu

import React from 'react'
import { toast } from 'react-toastify'

import Alert from './Alert'

const useToast = () => {
  const setToast = (body, options) => {
    toast(<Alert {...body} />, {
      autoClose: 5000,
      ...options
    })
  }

  return setToast
}

export default useToast

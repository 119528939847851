import React from 'react'
import styled from 'styled-components'

import { ResponsiveSVG } from 'ui/acro'
import { AppStoreIcon } from 'images/latest'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const DownloadText = styled.div`
  font-family: 'proxima-nova';
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.01925rem;
  @media only screen and (max-width: 1200px) {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.022rem;
  }
`

const AppStoreComponent = ({ style }) => (
  <Wrapper style={style}>
    <DownloadText>
      Download the <b>Alo Moves app</b> to take classes offline and practice
      anytime, anywhere.
    </DownloadText>
    <a
      target='_blank'
      href={'https://apps.apple.com/us/app/alo-moves/id601617403'}
    >
      <ResponsiveSVG SVG={AppStoreIcon} width='7.479rem' height='2.5rem' />
    </a>
  </Wrapper>
)

export default AppStoreComponent

import styled from 'styled-components'
import { compose, position, space } from 'styled-system'
import { css } from '@styled-system/css'

const NotificationBadge = styled('div')(
  css({
    display: 'inline-block',
    padding: '6px',
    borderRadius: '50%',
    backgroundColor: 'errorDefault'
  }),
  compose(position, space)
)

export default NotificationBadge

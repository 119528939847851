import { useEffect, useState } from 'react'
import lodashDebounce from 'lodash.debounce'

const useWindowResize = (cb) => {
  const getHeight = () =>
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth

  const [height, setHeight] = useState(getHeight())
  const [width, setWidth] = useState(getWidth())

  useEffect(() => {
    const setDimensions = () => {
      setHeight(getHeight())
      setWidth(getWidth())
    }

    const debouncedCallback = lodashDebounce(setDimensions, 50, {
      trailing: true
    })

    window.addEventListener('resize', debouncedCallback)

    return () => {
      window.removeEventListener('resize', debouncedCallback)
    }
  }, [])

  return { height, width }
}

export default useWindowResize

import React, { Fragment, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Transition } from 'react-transition-group'

// We have to be explicit about our imports here to prevent circular dependencies.
import useOutsideClick from 'hooks/useOutsideClick'
import {
  Flex,
  GridItem,
  HR,
  MainContainer,
  CloseButton
} from 'ui/bend/elements'
import { P } from 'ui/bend/typography'
import { renderPropComponent } from 'helpers'

const Overlay = styled(MainContainer)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: background ${(props) => props.transitionSpeed}ms ease-in-out;
  z-index: 10001;
  background: ${(props) =>
    props.state === 'entered' ? 'rgba(0, 0, 0, 0.8)' : ''};
  grid-auto-rows: min-content;
`

const ModalContainer = styled(GridItem)`
  ${(props) =>
    css({
      // padding: ['16px', '24px'],
      margin: ['', `${props.fullWidth ? '' : '0 auto'}`],
      maxWidth: props.fullWidth ? 'none' : '640px'
    })}
  max-height: calc(100vh - 16px);
  overflow-y: auto;
  flex-direction: column;
  grid-column: main-start / main-end;
  background: ${(props) => props.theme.colors.base};
  transition: transform ${(props) => props.transitionSpeed}ms ease-in-out;
  transform: ${(props) =>
    props.state === 'entered' ? 'translateY(0%)' : 'translateY(-100%)'};

  /* 
    We need the responsiveVideo prop to appropriately size our videojs player.
    It uses a negative margin trick to maintain aspect ratio, which means that it has 0 effective width.
    Thus, we manually set our width here and also set a max-width to keep the video from overflowing on short screens (e.g. landscape phones )
    max-width is the less of vh or vw plus the calculated aspect ratio, minus 48 to give a bit of breathing room.
    I hate magic numbers too...
  */
  ${(props) =>
    props.responsiveVideo
      ? `
    max-width: calc(100vmin + calc(100vmin * 0.5625) - 48px);
    width: 100%;
    `
      : ''}
`
const StyledClose = styled(CloseButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
`
export const ModalBody = styled(GridItem)``

ModalBody.defaultProps = {
  paddingLeft: '16px',
  paddingRight: '16px'
}

const Modal = ({
  ModalChild,
  closeModal,
  fullWidth,
  overlay,
  responsiveVideo,
  showClose,
  title,
  transitionSpeed
}) => {
  const ref = useRef()
  useOutsideClick(ref, closeModal, Boolean(ModalChild))

  return (
    <Fragment>
      {ModalChild &&
        ReactDOM.createPortal(
          <Transition
            in={overlay}
            timeout={transitionSpeed}
            component={null}
            appear
            mountOnEnter
            unmountOnExit
          >
            {(state) => (
              <Overlay state={state} transitionSpeed={transitionSpeed}>
                <ModalContainer
                  state={state}
                  ref={ref}
                  fullWidth={fullWidth}
                  transitionSpeed={transitionSpeed}
                  responsiveVideo={responsiveVideo}
                  id='modal-container'
                  role='dialog'
                  aria-live='polite'
                  position='relative'
                >
                  {showClose && (
                    <StyledClose
                      variant='dim'
                      onClick={closeModal}
                      aria-label='Close Modal'
                    />
                  )}
                  {title && (
                    <Flex
                      justifyContent={title ? 'space-between' : 'flex-end'}
                      alignItems={title ? 'baseline' : undefined}
                      p='16px'
                    >
                      <P variant='bold' color='primary' m='0 auto'>
                        {title}
                      </P>
                    </Flex>
                  )}
                  {title && <HR />}

                  {renderPropComponent(ModalChild)}
                </ModalContainer>
              </Overlay>
            )}
          </Transition>,
          document.body
        )}
    </Fragment>
  )
}

// See ModalProvider for props descriptions.
Modal.propTypes = {
  ModalChild: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  closeModal: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  overlay: PropTypes.bool,
  responsiveVideo: PropTypes.bool,
  showClose: PropTypes.bool,
  title: PropTypes.string,
  transitionSpeed: PropTypes.number.isRequired
}

export default Modal

import {
  SET_MODAL_START,
  SET_MODAL_FINISH,
  CLOSE_MODAL_START,
  CLOSE_MODAL_FINISH,
  CHANGE_TITLE
} from './actions'

export const INITIAL_STATE = {
  ModalChild: null,
  transitionSpeed: 300,
  fullWidth: true,
  onClose: null,
  showClose: true,
  prevFocus: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MODAL_START: {
      return {
        ...state,
        ...action.options,
        closeable: false,
        overlay: true,
        ModalChild: action.Modal
      }
    }
    case SET_MODAL_FINISH: {
      return {
        ...state,
        closeable: true
      }
    }
    case CLOSE_MODAL_START: {
      return {
        ...state,
        overlay: false
      }
    }
    case CLOSE_MODAL_FINISH: {
      return {
        ...INITIAL_STATE,
        prevFocus: null
      }
    }
    case CHANGE_TITLE: {
      return {
        ...state,
        title: action.title
      }
    }
  }
}

export default reducer

import React from 'react'
import { AnimationL } from 'images/latest'
import { ResponsiveSVG } from 'ui/acro'

const AnimationLIcon = ({
  color = 'transparent',
  width = '10px',
  height = '71px'
}) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled animation-fill-2'
    color={color}
    SVG={AnimationL}
  />
)

export default AnimationLIcon

import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { StarFilled } from 'images/bend/rating'

const StarIcon = ({
  color = 'black',
  width = '30px',
  height = '30px'
}) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    fill={color}
    SVG={StarFilled}
  />
)

export default StarIcon

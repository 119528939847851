import styled from 'styled-components'
import { css } from '@styled-system/css'
import { HR } from 'ui/acro'

const SectionDivider = styled(HR)(() =>
  css({
    mx: ['16px', '16px', '16px', '0px'],
    mt: ['30px', '40px'],
    mb: ['20px', '40px']
  })
)
export default SectionDivider

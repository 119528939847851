import React from 'react'
import { AnimationO } from 'images/latest'
import { ResponsiveSVG } from 'ui/acro'

const AnimationOIcon = ({
  color = 'transparent',
  width = '44px',
  height = '44px'
}) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled animation-fill-3'
    color={color}
    SVG={AnimationO}
  />
)

export default AnimationOIcon

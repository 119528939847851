import styled from 'styled-components'
import { variant } from 'styled-system'
import { P } from 'ui/acro'

const CardSubtitle = styled(P)(
  {
    fontFamily: 'Proxima-Nova',
    fontSize: '0.875em',
    fontWeight: '400',
    letterSpacing: 'normal',
    lineHeight: '16.1px',
    color: 'grey5'
  },
  variant({
    variants: {
      primary: {
        letterSpacing: '1px',
        fontSize: ['12px', '14px', '14px', '16px'],
        color: ' black',
        lineHeight: '22px',
        display: ['none', 'block', 'block', 'block']
      }
    }
  })
)
export default CardSubtitle

import { CLEAR_DATA, FETCH_START, FETCH_SUCCESS, FETCH_FAIL } from './actions'

export const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case CLEAR_DATA:
      return {
        ...state,
        data: null
      }
    case FETCH_START:
      return {
        ...state,
        loading: true,
        error: false
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      throw new Error()
  }
}

export default dataFetchReducer

import styled from 'styled-components'
import { css } from '@styled-system/css'
import { P } from 'ui/acro'

const Count = styled(P)`
  ${css({
    fontWeight: '400',
    fontSize: '16px',
    color: '#636363',
    margin: '0', // overwrite legacy p margin in application.css
    lineHeight: '16px'
  })}
`
export default Count

export const axiosInstanceConfig = {
  timeout: 20000,
  baseURL: '/api/v2',
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  xsrfHeaderName: 'X-CSRF-Token',
  withCredentials: true
}

export const interceptRequest = (config) => {
  config.headers = Object.assign({}, config.headers, {
    'X-CSRF-Token': document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
  })
  return config
}

export const interceptResponseSucess = (response) => {
  return response
}

export const interceptResponseError = (error) => {
  const response = error.response

  if (response) {
    if (response.status >= 500) {
      response.message =
        "Oops, something went wrong. Please contact support@alomoves.com and we'll get to the bottom of it."
    } else {
      response.message = response.data.error || response.data.message
      response.messages = response.data.errors || []
    }
  }

  return Promise.reject(error)
}

import React from 'react'
import { toast } from 'react-toastify'

import Alert from './Alert'

const useAlert = () => {
  const setAlert = (body, options) => {
    toast(<Alert alert {...body} />, options)
  }

  return setAlert
}

export default useAlert

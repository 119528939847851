import { useEffect } from 'react'

// This hook will check to see if the page is loaded from cache. If so, it will reload the page.
const useResetPage = () => {
  useEffect(() => {
    const resetProcessing = (e) => {
      if (e.persisted) {
        window.location.reload(true)
      }
    }

    window.addEventListener('pageshow', resetProcessing, false)
  }, [])
}

export default useResetPage

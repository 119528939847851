import { buildYupValidator } from 'helpers'

const SINGLE_DROPDOWN = 'single_dropdown'
const fieldTypes = { SINGLE_DROPDOWN }

const customFieldsInitializer = (fields) => {
  const res = {
    type: 'customFields',
    loadError: null,
    values: {},
    validators: {},
    state: {
      fieldNames: [],
      fieldTypes
    },
    actions: {}
  }

  fields.forEach((field) => {
    if (!field.validationType) {
      throw new Error('Every custom field must include a validator.')
    }

    if (!Object.values(fieldTypes).includes(field.inputComponent)) {
      throw new Error(`${field.inputComponent} is not a valid field type.`)
    }

    // Add the field's values and default value to our values object for Formik to consume.
    res.values[field.fieldName] = field.defaultValue || ''

    // We put the fieldNames in our customFields state so we can dynamically create our PUT object.
    res.state.fieldNames.push(field.fieldName)

    const fieldValidator = buildYupValidator({
      validationType: field.validationType,
      validationTypeError: field.validationTypeError,
      validations: field.validations
    })

    res.validators = {
      ...res.validators,
      [field.fieldName]: fieldValidator
    }
  })

  return res
}

export default customFieldsInitializer

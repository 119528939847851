import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { ContentPlaylist } from 'images/latest'

const ContentPlaylistIcon = ({ color = 'black' }) => (
  <ResponsiveSVG
    width='16px'
    height='16px'
    className='unstyled'
    fill={color}
    SVG={ContentPlaylist}
  />
)

export default ContentPlaylistIcon

import { useState, useEffect } from 'react'
import useWindowResize from './useWindowResize'

const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(true)
  const { width } = useWindowResize()
  useEffect(() => {
    setIsDesktop(width < 1200 ? false : true)
  }, [width])

  return isDesktop
}

export default useIsDesktop

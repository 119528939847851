import React from 'react'
import { AnimationMoves } from 'images/latest'
import { ResponsiveSVG } from 'ui/acro'

const AnimationMovesIcon = ({ color, width = '220px', height = '45px' }) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    fill={color}
    SVG={AnimationMoves}
  />
)

export default AnimationMovesIcon
